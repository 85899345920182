import React from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import userConst from "../constants/UserConstants";

const getUserForUUID = (users, uuid) => {
    return _.find(users, (exstingUser) => {
        return exstingUser.uuid == uuid;
    });
};

const getUserNameForUUID = (users, uuid) => {
    const user = getUserForUUID(users, uuid);
    return `${user.name} ${user.lastName}`;
};

const isInvalidName = (user) =>
    !!user?.name && (user.name.length < 4 || user.name.length > 20);

const isInvalidEmal = (user) =>
    !!user?.email &&
    !userConst.EMAIL_REGEX.test(String(user.email).toLowerCase());

const isInvalidPass = (user) => !!user?.pass && user.pass.length < 8;

const isInvalidPassConfirm = (user) => user.password_confirm != user.pass;

function createTextInputFild({ title, name }) {
    const { user, errors, message } = this.state;
    let defaultVal =
        name != "pass" || name == "password_confirm" ? user[name] || "" : "";
    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Control
                type={"text"}
                name={name}
                defaultValue={defaultVal}
                isInvalid={errors[name]}
                onChange={
                    name == "pass" || name == "password_confirm"
                        ? this.handleChangePassword
                        : this.handleChangeText
                }
            />
            {errors[name] && (
                <Form.Text className="text-danger">{message[name]}</Form.Text>
            )}
        </Form.Group>
    );
}

function createRoleDropDownList({ title, name }) {
    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Control
                as="select"
                name={name}
                defaultValue={
                    this.state.propertyFoUpdate[name] || this.state.user[name]
                }
                onChange={this.handleChangeText}
            >
                <option value="3">Igrač</option>
                <option value="4">Igrač Klub</option>
                <option value="9">Trainer</option>
                <option value="5">Trainer Klub</option>
                <option value="6">Watcher</option>
                <option value="7">League</option>
                <option value="8">Football</option>
                <option value="2">Admin</option>
                <option value="1">Disabled</option>
            </Form.Control>
        </Form.Group>
    );
}

function createNumberInputFild({ title, name }) {
    const { errors, message } = this.state;
    return (
        <Form.Group>
            <Form.Label>{title}</Form.Label>
            <Form.Control
                type="number"
                name={name}
                isInvalid={errors[name]}
                onChange={this.handleChangeNumber}
            />
            {errors[name] && (
                <Form.Text className="text-danger">{message[name]}</Form.Text>
            )}
        </Form.Group>
    );
}

function createFormLabel(label) {
    return (
        <Form.Label key={Math.random()}>
            <h5>{label}</h5>
        </Form.Label>
    );
}

function handleChangePassword(e) {
    e.target.value
        ? this.setState({
              propertyFoUpdate: {
                  ...this.state.propertyFoUpdate,
                  [e.target.name]: e.target.value,
              },
          })
        : delete this.state.propertyFoUpdate[e.target.name];
}

function handleChangeText(e) {
    e.target.value != this.state.user[e.target.name]
        ? this.setState({
              propertyFoUpdate: {
                  ...this.state.propertyFoUpdate,
                  [e.target.name]: e.target.value,
              },
          })
        : delete this.state.propertyFoUpdate[e.target.name];
}

function handleChangeNumber(e) {
    e.target.value != 0
        ? this.setState({
              propertyFoUpdate: {
                  ...this.state.propertyFoUpdate,
                  [e.target.name]: +e.target.value,
              },
          })
        : delete this.state.propertyFoUpdate[e.target.name];
}

const getRoleEventTitle = (event) => {
    switch (event.roleEventID) {
        case userConst.ROLE_EVENT_ID.Player:
            return userConst.ROLE_EVENT_TITLE.Player;
            break;
        case userConst.ROLE_EVENT_ID.Rekreativac:
            return userConst.ROLE_EVENT_TITLE.Rekreativac;
            break;
        case userConst.ROLE_EVENT_ID.Trener:
            return userConst.ROLE_EVENT_TITLE.Trener;
            break;
        case userConst.ROLE_EVENT_ID.GostTrener:
            return userConst.ROLE_EVENT_TITLE.GostTrener;
            break;
        case userConst.ROLE_EVENT_ID.Gost:
            return `${userConst.ROLE_EVENT_TITLE.Gost} - ${event.guest}`;
            break;
        case userConst.ROLE_EVENT_ID.Turnir:
            return userConst.ROLE_EVENT_TITLE.Turnir;
            break;
        case userConst.ROLE_EVENT_ID.Radovi:
            return userConst.ROLE_EVENT_TITLE.Radovi;
            break;
        case userConst.ROLE_EVENT_ID.CashPlayer:
            return `${userConst.ROLE_EVENT_TITLE.CashPlayer} - ${event.guest}`;
            break;
        case userConst.ROLE_EVENT_ID.Football:
            return `${userConst.ROLE_EVENT_TITLE.Football} - ${event.guest}`;
            break;
        case userConst.ROLE_EVENT_ID.Liga:
            return `LigaS - ${event.guest}`;
            break;
        case userConst.ROLE_EVENT_ID.LigaDouble:
            return `LigaD - ${event.guest}`;
            break;
        case userConst.ROLE_EVENT_ID.Fix:
            return `${userConst.ROLE_EVENT_TITLE.Fix} - ${event.guest}`;
            break;
        default:
            return userConst.ROLE_EVENT_TITLE.Player;
    }
};

const getRoleEventTitleForSumReportMouth = (event) => {
    switch (event) {
        case userConst.ROLE_EVENT_ID.Player:
            return userConst.ROLE_EVENT_TITLE.Player;
            break;
        case userConst.ROLE_EVENT_ID.Rekreativac:
            return userConst.ROLE_EVENT_TITLE.Rekreativac;
            break;
        case userConst.ROLE_EVENT_ID.Trener:
            return userConst.ROLE_EVENT_TITLE.Trener;
            break;
        case userConst.ROLE_EVENT_ID.GostTrener:
            return userConst.ROLE_EVENT_TITLE.GostTrener;
            break;
        case userConst.ROLE_EVENT_ID.Gost:
            return `${userConst.ROLE_EVENT_TITLE.Gost}`;
            break;
        case userConst.ROLE_EVENT_ID.Turnir:
            return userConst.ROLE_EVENT_TITLE.Turnir;
            break;
        case userConst.ROLE_EVENT_ID.Radovi:
            return userConst.ROLE_EVENT_TITLE.Radovi;
            break;
        case userConst.ROLE_EVENT_ID.CashPlayer:
            return userConst.ROLE_EVENT_TITLE.CashPlayer;
            break;
        case userConst.ROLE_EVENT_ID.Football:
            return `${userConst.ROLE_EVENT_TITLE.Football}`;
            break;
        case userConst.ROLE_EVENT_ID.Liga:
            return `${userConst.ROLE_EVENT_TITLE.Liga}`;
            break;
        case userConst.ROLE_EVENT_ID.LigaDouble:
            return `${userConst.ROLE_EVENT_TITLE.LigaDouble}`;
            break;
        case userConst.ROLE_EVENT_ID.Fix:
            return `${userConst.ROLE_EVENT_TITLE.Fix}`;
            break;
        default:
            return userConst.ROLE_EVENT_TITLE.Player;
    }
};

const getRoleEventIDForUserRoleId = (roleId) => {
    switch (roleId) {
        case userConst.ROLE_ID.Admin:
            return userConst.ROLE_EVENT_ID.Trener;
            break;
        case userConst.ROLE_ID.Player:
            return userConst.ROLE_EVENT_ID.Player;
            break;
        case userConst.ROLE_ID.PlayerRekreativac:
            return userConst.ROLE_EVENT_ID.Rekreativac;
            break;
        case userConst.ROLE_ID.Trainer:
            return userConst.ROLE_EVENT_ID.Trener;
            break;
        case userConst.ROLE_ID.GostTrainer:
            return userConst.ROLE_EVENT_ID.GostTrener;
            break;
        case userConst.ROLE_ID.Watcher:
            return userConst.ROLE_EVENT_ID.Gost;
            break;
        case userConst.ROLE_ID.Turnir:
            return userConst.ROLE_EVENT_ID.Turnir;
            break;
        case userConst.ROLE_ID.League:
            return userConst.ROLE_EVENT_ID.Liga;
            break;
        case userConst.ROLE_ID.Football:
            return userConst.ROLE_EVENT_ID.Football;
            break;
        default:
            return userConst.ROLE_EVENT_ID.Player;
    }
};

export {
    getUserForUUID,
    getUserNameForUUID,
    isInvalidName,
    isInvalidEmal,
    isInvalidPass,
    isInvalidPassConfirm,
    createTextInputFild,
    createNumberInputFild,
    createRoleDropDownList,
    createFormLabel,
    handleChangePassword,
    handleChangeText,
    handleChangeNumber,
    getRoleEventIDForUserRoleId,
    getRoleEventTitle,
    getRoleEventTitleForSumReportMouth,
};
