import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import { Form, Table } from "react-bootstrap";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Const
import userConst from "../constants/UserConstants";
import calendarConstats from "../constants/CaledarConstants";
//Utils
import moment from "moment";
import { find } from "lodash";
import { getHistory } from "../api/usersApi";
import { getErrorState } from "../utils/ErrorHandler";
import { getUserNameForUUID } from "../utils/UserUtils";
import { getEventsForUUID, handleCloseAlert } from "../utils/EventUtils";

class HistoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            user: {},
            allUsers: [],
            historyEvents: [],
            historyCredit: [],
            creditInfo: [],
            selectUUID: "",
            radio: "events",
            error: {
                show: false,
                status: 0,
            },
            spinner: true,
        };
        this.getErrorState = getErrorState.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);

        this.updateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.renderDropDownUserList = this.renderDropDownUserList.bind(this);
    }

    componentDidMount() {
        moment.locale(calendarConstats.LOCALE_DATE_FORMAT);
        getHistory().then((response) => {
            this.updateState(response);
        });
    }

    updateState(response) {
        const stateObj = !!response.status
            ? this.getErrorState(response)
            : {
                  events: [...(response?.events || [])],
                  creditInfo: [...(response?.creditInfo || [])],
                  allUsers: [...(response?.users || [])],
                  user: { ...response?.user } || {},
                  historyEvents: getEventsForUUID(
                      response?.events || [],
                      response?.user.uuid || ""
                  ),
                  historyCredit: getEventsForUUID(
                      response?.creditInfo || [],
                      response?.user.uuid || ""
                  ),
                  selectUUID: response?.user.uuid || "",
              };

        this.setState({
            spinner: false,
            ...stateObj,
        });
    }

    onRadioChange(radio) {
        this.setState({
            radio,
        });
    }

    handleChange(e) {
        if(e.target.value) {
            this.setState({
                historyEvents: getEventsForUUID(this.state.events, e.target.value),
                selectUUID: e.target.value,
                historyCredit: getEventsForUUID(
                    this.state.creditInfo,
                    e.target.value
                ),
            });
        } else  {
            this.setState({
                historyEvents: this.state.events,
                selectUUID: e.target.value,
                historyCredit: [],
            });
        }
    }

    renderDropDownUserList() {
        const listItems = this.state.allUsers.map((existUser, index) => (
            <option key={index} value={existUser.uuid}>
                {existUser.lastName} {existUser.name}
            </option>
        ));
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-5">
                    <Form.Label>Korisnici</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={this.handleChange}
                        value={this.state.selectUUID}
                    >
                         <option value="">
                            Svi Termini
                        </option>
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    }

    renderTableCredit() {
        return this.state.historyCredit.map((existCredit, index) => {
            const {
                userUuid,
                executeUserUuid,
                credit,
                created_at,
            } = existCredit;
            return (
                <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{getUserNameForUUID(this.state.allUsers, userUuid)}</td>
                    <td>
                        {moment(created_at).format(
                            calendarConstats.DATE_FORMAT_VIEW_AND_TIME
                        )}
                    </td>
                    <td>{credit}</td>
                    <td>
                        {getUserNameForUUID(
                            this.state.allUsers,
                            executeUserUuid
                        )}
                    </td>
                </tr>
            );
        });
    }

    renderTableEvents() {
        return this.state.historyEvents.map((existEvent, index) => {
            const {
                start,
                end,
                resourceId,
                userUuid,
                executeUserUuid,
                credit,
                deleted,
                created_at,
                updated_at,
            } = existEvent;

            const isDelete = deleted == "1" || deleted == "2";
            const deleteText = isDelete
                ? deleted == "1"
                    ? "Obrisan termin / vraćen kredit"
                    : "Opravdan izostanak / vraćen kredit"
                : "";

            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{getUserNameForUUID(this.state.allUsers, userUuid)}</td>
                    <td>
                        {moment(created_at).format(
                            calendarConstats.DATE_FORMAT_VIEW_AND_TIME
                        )}
                    </td>
                    <td>
                        {`${moment(start).format(
                            calendarConstats.DATE_FORMAT_VIEW
                        )} od ${moment(start).format(
                            calendarConstats.TIME_FORMAT_VIEW
                        )} do ${moment(end).format(
                            calendarConstats.TIME_FORMAT_VIEW
                        )}`}
                    </td>
                    <td>
                        {
                            find(calendarConstats.RESOURCE_MAP_ADMIN, [
                                "resourceId",
                                resourceId,
                            ]).resourceTitle
                        }
                    </td>
                    <td>{credit}</td>
                    <td>{deleteText}</td>
                    <td>
                        {isDelete &&
                            moment(updated_at).format(
                                calendarConstats.DATE_FORMAT_VIEW_AND_TIME
                            )}
                    </td>
                    <td>
                        {isDelete &&
                            getUserNameForUUID(
                                this.state.allUsers,
                                executeUserUuid
                            )}
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div className="container-fluid table-responsive">
                <SpinnerComponents show={this.state.spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={this.state.error}
                />
                {this.state.user.roleId == userConst.ROLE_ID.Admin &&
                    this.renderDropDownUserList()}
                <div className="d-flex justify-content-around align-items-center pb-5 col-sm-5 mx-auto">
                    <Form.Check
                        type="radio"
                        name="check"
                        id="event-check"
                        label="Istorija Termina"
                        defaultChecked
                        onClick={(e) => this.onRadioChange("events")}
                    />
                    <Form.Check
                        type="radio"
                        name="check"
                        id="credit-check"
                        label="Istorija Kredita"
                        onClick={(e) => this.onRadioChange("credit")}
                    />
                </div>
                {this.state.radio == "events" ? (
                    <Table className="table" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Korisnik</th>
                                <th>Kreirano</th>
                                <th>Datum Termina</th>
                                <th>Teren</th>
                                <th>Kredit</th>
                                <th>Obrisano</th>
                                <td>Uređeno</td>
                                <th>Izvršio</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableEvents()}</tbody>
                    </Table>
                ) : (
                    <Table className="table" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Korisnik</th>
                                <th>Kreirano</th>
                                <th>Kredit</th>
                                <th>Izvršio</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableCredit()}</tbody>
                    </Table>
                )}
            </div>
        );
    }
}

export default HistoryComponent;

if (document.getElementById("history")) {
    ReactDOM.render(<HistoryComponent />, document.getElementById("history"));
}
