export default {
    ROLE_EVENT_TITLE: {
        Player: "Igrač",
        Rekreativac: "Igrač Klub",
        Trener: "Trener Klub",
        GostTrener: "Trener",
        Gost: "Gost",
        Turnir: "TURNIR",
        Radovi: "RADOVI",
        Liga: "Liga Singlova",
        Football: "Football",
        Fix: "Fiksni",
        CashPlayer: "Član",
        LigaDouble: "Liga Dublova"
    },
    ROLE_EVENT_ID: {
        Player: 11,
        Rekreativac: 12,
        Trener: 13,
        Gost: 14,
        Turnir: 15,
        Liga: 16,
        Football: 17,
        GostTrener: 18,
        Radovi: 20,
        Fix: 21,
        CashPlayer: 22,
        LigaDouble: 23,
    },
    ROLE_ID: {
        Disabled: 1,
        Admin: 2,
        Player: 3,
        PlayerRekreativac: 4,
        Trainer: 5,
        Watcher: 6,
        League: 7,
        Football: 8,
        GostTrainer: 9,
        Turnir: 15,
    },
    ROLE: {
        "1": "Disabled",
        "2": "Admin",
        "3": "Igrač",
        "4": "Igrač Klub",
        "5": "Trainer Klub",
        "6": "Watcher",
        "7": "League",
        "8": "Football",
        "9": "Trainer",
    },
    EMAIL_REGEX: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
};
