import axios from 'axios';

const updateUser = (user, uuid) => {
    return axios
        .post("users/update", {
            user,
            uuid,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const updateUserPassword = (password, uuid) => {
    return axios
        .post("users/update/password", {
            password,
            uuid,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const getAllUsers = () => {
    return axios
        .get("users/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const getLoginUserInfo = () => {
    return axios
        .get("info/user")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const getHistory = () => {
    return axios
        .get("history/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

export {
    updateUser,
    getAllUsers,
    getHistory,
    getLoginUserInfo,
    updateUserPassword,
};
