import { map } from "lodash";
import moment from "moment";

const defaultEventObj = {
    id: 0,
    start: 0,
    end: 0,
    resourceId: 0,
    title: 0,
    userUuid: "",
    executeUserUuid: "",
    roleEventID: 0,
    credit: 0,
    guest: "",
    deleted: false,
    created_at: null,
    updated_at: null,
};

const getObejctWithUniformProperties = (
    sourceObj,
    targetObj,
    isTimeStamp = true
) => {
    return Object.keys(sourceObj).forEach((key) => {
        if (key in targetObj) {
            key == "start" || key == "end"
                ? (sourceObj[key] = isTimeStamp
                      ? new Date(targetObj[key]).getTime()
                      : moment(targetObj[key], 'YYYY-MM-DD HH:mm').toDate())
                : (sourceObj[key] = targetObj[key]);
        }
    });
};

const getCalendarEvents = (events) => {
    return map(events, (event) => {
        let newObj = Object.assign({}, defaultEventObj);
        getObejctWithUniformProperties(newObj, event, false);
        return newObj;
    });
};

export { getObejctWithUniformProperties, getCalendarEvents };
