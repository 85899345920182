function onToggleModel() {
    this.setState({
        showModel: !this.state.showModel,
    });
}

function onToggleCancelEventModel() {
    this.setState({
        showCancelEventModel: !this.state.showCancelEventModel,
    });
}

export { onToggleModel, onToggleCancelEventModel };
