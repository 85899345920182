import React from "react";
import Alert from "react-bootstrap/Alert";
import { errorHandler } from "../utils/ErrorHandler";

const ErrorComponent = (props) => {
    const { onCloseAlert, error } = props;
    const errorMess = errorHandler(error.status);
    return (
        error.show && (
            <Alert
                style={{
                    maxWidth: 300,
                    position: "sticky",
                    top: "40%",
                    margin: "0 auto",
                    zIndex: "99999",
                }}
                variant="danger"
                onClose={() => onCloseAlert()}
                dismissible
            >
                <Alert.Heading>{errorMess.title}</Alert.Heading>
                <h6>{errorMess.subject}</h6>
            </Alert>
        )
    );
};
export default ErrorComponent;
