import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
//Utils
import {
    isInvalidName,
    isInvalidEmal,
    isInvalidPass,
    isInvalidPassConfirm,
    createTextInputFild,
    createNumberInputFild,
    createRoleDropDownList,
    createFormLabel,
    handleChangePassword,
    handleChangeText,
    handleChangeNumber,
} from "../utils/UserUtils";
//const
import messageConst from "../constants/MessageConstants";
const textInputFilds = [
    {
        title: "Ime",
        name: "name",
    },
    {
        title: "Prezime",
        name: "lastName",
    },
    {
        title: "Telefon",
        name: "phone",
    },
    {
        title: "Datum Rodjenja",
        name: "dateOfBirth",
    },
    {
        title: "Komentar",
        name: "comment",
    },
    {
        title: "Role",
        name: "roleId",
    },
    {
        title: "Email",
        name: "email",
    },
    {
        title: "Nova Šifra",
        name: "pass",
    },
    {
        title: "Potvrda Šifre",
        name: "password_confirm",
    },
];

class UserUpdateComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            propertyFoUpdate: {},
            errors: {
                email: false,
                name: false,
                pass: false,
                password_confirm: false,
            },
            message: {
                email: messageConst.INVALID_EMAIL,
                name: messageConst.INVALID_NAME,
                pass: messageConst.INVALID_PASS,
                password_confirm: messageConst.INVALID_PASS_CONFIRM,
            },
            buttonIsDisabled: false,
        };
        this.handleChangeText = handleChangeText.bind(this);
        this.handleChangeNumber = handleChangeNumber.bind(this);
        this.createTextInputFild = createTextInputFild.bind(this);
        this.handleChangePassword = handleChangePassword.bind(this);
        this.createNumberInputFild = createNumberInputFild.bind(this);
        this.createRoleDropDownList = createRoleDropDownList.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    static getDerivedStateFromProps(props, current_state) {
        return !props.show ? { propertyFoUpdate: {} } : { user: props.user };
    }
    

    handleValidation() {
        const { propertyFoUpdate, errors } = this.state;
        const invalidName = isInvalidName(propertyFoUpdate);
        const invalidEmal = isInvalidEmal(propertyFoUpdate);
        const invalidPass = isInvalidPass(propertyFoUpdate);
        const invalidPassConfirm = isInvalidPassConfirm(propertyFoUpdate);
        this.setState({
            errors: {
                ...errors,
                ...{
                    name: invalidName,
                    email: invalidEmal,
                    pass: invalidPass,
                    password_confirm: invalidPassConfirm,
                },
            },
        });
        return !(
            invalidName ||
            invalidEmal ||
            invalidPass ||
            invalidPassConfirm
        );
    }

    render() {
        const { onlyCredit, show, onToggleModal } = this.props;
        return (
            <Modal
                show={show}
                onHide={onToggleModal}
                animation={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{messageConst.UPDATE_USER_TITLE}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {onlyCredit
                        ? [
                              createFormLabel(
                                  `Trenutno stanje: ${this.state.user.credit}`
                              ),
                              this.createNumberInputFild({
                                  title: "Kredit",
                                  name: "credit",
                              }),
                          ]
                        : textInputFilds.map((inputFiled, index) => {
                              return (
                                  <div key={index + 1}>
                                      {[
                                          inputFiled.name == "pass" &&
                                              createFormLabel(
                                                  `Trenutna Šifra - ${this.state.user?.pass}`
                                              ),
                                          inputFiled.name != "roleId"
                                              ? this.createTextInputFild(
                                                    inputFiled
                                                )
                                              : this.createRoleDropDownList(
                                                    inputFiled
                                                ),
                                      ]}
                                  </div>
                              );
                          })}
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <Button
                        className="bg-success"
                        disabled={this.state.buttonIsDisabled}
                        onClick={() => {
                            this.setState({ buttonIsDisabled: true });
                            if (this.handleValidation()) {
                                this.props.handle(this.state.propertyFoUpdate, this.state.user.uuid);
                            }
                            setTimeout(() => { this.setState({ buttonIsDisabled: false }); }, 5000);
                        }}
                    >
                        Izmeni
                    </Button>
                    <Button onClick={onToggleModal}>Zatvori</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UserUpdateComponent;
