import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import { Form, Table } from "react-bootstrap";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//API
import { mouthReport } from "../api/eventsApi";
//Utils
import { handleCloseAlert } from "../utils/EventUtils";
//Const
import userConst from "../constants/UserConstants";

class ReportMouthComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            user: {},
            allUsers: [],
            reports: [],
            selectUUID: "",
            spinner: true,
            error: {
                show: false,
                status: 0,
            },
        };
        this.renderTable = this.renderTable.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);
        this.renderDropDownUserList = this.renderDropDownUserList.bind(this);
    }

    componentDidMount() {
        mouthReport().then((response) => {
            !!response.status
                ? this.setState({
                      spinner: false,
                      error: {
                          ...this.state.error,
                          ...{
                              show: true,
                              status: response.status,
                          },
                      },
                  })
                : this.setState({
                      reports: [...(response?.reports || [])],
                      allUsers: [...(response?.users || [])],
                      user: { ...response?.user } || {},
                      isAdmin:
                          response.user &&
                          response.user.roleId == userConst.ROLE_ID.Admin,
                      selectUUID: response?.user.uuid || "",
                      spinner: false,
                  });
        });
    }

    handleChange(e) {
        this.setState({
            selectUUID: e.target.value,
        });
    }

    renderDropDownUserList() {
        const listItems = this.state.allUsers.map((existUser, index) => (
            <option key={index} value={existUser.uuid}>
                {existUser.lastName} {existUser.name} - {userConst.ROLE[existUser.roleId]}
            </option>
        ));
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-5">
                    <Form.Label>Korisnici</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={this.handleChange}
                        value={this.state.selectUUID}
                    >
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    }

    renderTable() {
        return this.state.reports
            .filter((existReports) => {
                return existReports.userUuid == this.state.selectUUID;
            })
            .map((filterReport, index) => {
                return (
                    <tr key={index + 1}>
                        <td>{index + 1}</td>
                        <td>{filterReport.month}</td>
                        <td>{filterReport.total}</td>
                    </tr>
                );
            });
    }

    render() {
        return (
            <div className="container-fluid table-responsive">
                <SpinnerComponents show={this.state.spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={this.state.error}
                />
                <h4 className="text-center pb-3">
                    Mesečni izveštaj
                </h4>
                {this.state.isAdmin && this.renderDropDownUserList()}
                <Table
                    className="table "
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ maxWidth: "400px", margin: "0 auto" }}
                >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Mesec</th>
                            <th>Potroseni krediti po mesecu</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTable()}</tbody>
                </Table>
            </div>
        );
    }
}

export default ReportMouthComponent;

if (document.getElementById("report")) {
    ReactDOM.render(
        <ReportMouthComponent />,
        document.getElementById("report")
    );
}
