import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import Table from "react-bootstrap/Table";
import { PlusCircle } from "react-bootstrap-icons";
import UserUpdateComponent from "./UserUpdateComponent";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Const
import userConst from "../constants/UserConstants";
import bootstrapConstats from "../constants/BootstrapConstants";
import calendarConstats from "../constants/CaledarConstants";
//Utils
import _ from "lodash";
import moment from "moment";
import { getErrorState } from "../utils/ErrorHandler";
import { handleCloseAlert } from "../utils/EventUtils";

//API
import { getAllUsers, updateUser } from "../api/usersApi";

class UsersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allUsers: [],
            showUpdateModel: false,
            selectedUser: {},
            onlyCredit: true,
            spinner: true,
            error: {
                show: false,
                status: 0,
            },
        };
        this.getErrorState = getErrorState.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);

        this.updateState = this.updateState.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.renderTableData = this.renderTableData.bind(this);
        this.handleUserUpdateModel = this.handleUserUpdateModel.bind(this);
    }

    componentDidMount() {
        getAllUsers().then((response) => {
            this.updateState(response);
        });
    }

    updateState(response) {
        const stateObj = !!response.status
            ? this.getErrorState(response)
            : {
                  allUsers: [...(response?.users || [])],
              };

        this.setState({
            selectedUser: {},
            showUpdateModel: false,
            spinner: false,
            ...stateObj,
        });
    }

    handleUserUpdateModel(user, onlyCredit = false) {
        this.setState({
            selectedUser: user,
            onlyCredit,
            showUpdateModel: !this.state.showUpdateModel,
        });
    }

    handleUpdate(user, uuid) {
        _.isEmpty(user)
            ? this.setState({
                  showUpdateModel: !this.state.showUpdateModel,
                  selectedUser: {},
              })
            : updateUser(_.omit(user, ["password_confirm"]), uuid).then(
                  (response) => {
                      this.updateState(response);
                  }
              );
    }

    renderTableData() {
        return this.state.allUsers.map((existUser, index) => {
            return (
                <tr key={index + 1}>
                    <td style={{width: '30px'}} >{index + 1}</td>
                    <td style={{width: '80px'}} >{userConst.ROLE[existUser.roleId]}</td>
                    <td>{existUser.lastName} {existUser.name}</td>
                    <td className="d-flex justify-content-between align-items-center">
                        {existUser.credit}

                        <button
                            type="button"
                            className={`btn ${bootstrapConstats.CALENDAR_BUTTON}`}
                            onClick={() => {
                                this.handleUserUpdateModel(existUser, true);
                            }}
                        >
                            <PlusCircle />
                        </button>
                    </td>
                    <td>{existUser.email}</td>
                    <td>{existUser.pass}</td>
                    <td>{existUser.phone}</td>
                    <td style={{width: '120px'}} >{existUser.dateOfBirth}</td>
                    <td style={{width: '300px'}} >{existUser.comment}</td>
                    <td style={{width: '160px'}}>{existUser.last_login_at ? moment(existUser.last_login_at).format(calendarConstats.DATE_FORMAT_VIEW_AND_TIME) : ""}</td>
                    <td className="d-flex justify-content-center align-items-center" > 
                        {
                            <button
                                type="button"
                                className={`btn ${bootstrapConstats.CALENDAR_BUTTON}`}
                                onClick={() => {
                                    this.handleUserUpdateModel(existUser);
                                }}
                            >
                                Update
                            </button>
                        }
                    </td>
                </tr>
            );
        });
    }

    render() {
        const {
            showUpdateModel,
            selectedUser,
            onlyCredit,
            spinner,
            error,
        } = this.state;
        return (
            <div className="container-fluid table-responsive">
                <SpinnerComponents show={spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={error}
                />
                <UserUpdateComponent
                    show={showUpdateModel}
                    user={selectedUser}
                    onlyCredit={onlyCredit}
                    handle={this.handleUpdate}
                    onToggleModal={() => {
                        this.setState({
                            showUpdateModel: !showUpdateModel,
                        });
                    }}
                />
                <Table className="table" striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Rola</th>
                            <th>Ime Prezime</th>
                            <th>Krediti</th>
                            <th>Email</th>
                            <th>Šifra</th>
                            <th>Telefon</th>
                            <th>Datum Rodjenja</th>
                            <th>Komentar</th>
                            <th>Poslednje logovanje</th>
                            <th>Akcije</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableData()}</tbody>
                </Table>
            </div>
        );
    }
}

export default UsersComponent;

if (document.getElementById("users")) {
    ReactDOM.render(<UsersComponent />, document.getElementById("users"));
}
