import axios from 'axios';

const getGuestEvents = () => {
    return axios
        .get("guest/events")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const postEvent = (event) => {
    return axios
        .post("events/create", {
            event,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const editEvent = (event) => {
    return axios
        .post("events/edit", {
            event,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const getAllEvents = () => {
    return axios
        .get("events/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const deleteEvent = (event, deleteOption) => {
    return axios
        .delete("events/delete", {
            data: {
                event,
                delete: deleteOption,
            },
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const deleteCancelEvents = (event, deleteOption) => {
    return axios
        .delete("cancel/delete", {
            data: {
                event,
                delete: deleteOption,
            },
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const mouthReport = () => {
    return axios
        .get("report/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const getAllCancelEvents = () => {
    return axios
        .get("cancel/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

export {
    postEvent,
    getAllEvents,
    deleteEvent,
    editEvent,
    getGuestEvents,
    getAllCancelEvents,
    deleteCancelEvents,
    mouthReport,
};
