import React from "react";
import { Modal, Button, Form} from "react-bootstrap";

const EditEventModel = (props) => {
    const title = "Izmena Termin";
    let editName = "";

    const handleValidation = () => {
        let isValid = true;
        if ( editName == "" ) {
            isValid = false;
        }
        return isValid;
    };

    return (
        <Modal centered show={props.show} onHide={props.onToggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className="text-center">Trenutni naziv: {props.event.guest}</h5>
                <div className="d-flex justify-content-around align-items-center pb-4 pt-4 mx-auto">
                    <Form.Control
                        type="text"
                        placeholder="Gost / Liga / Fudbal"
                        onChange={(e) =>
                            editName = e.target.value
                        }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button
                    className="bg-secondary"
                    onClick={() => {
                        handleValidation() && props.handleEdit(editName);
                    }}
                >
                    Da
                </Button>
                <Button onClick={props.onToggleModal}>Ne</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default EditEventModel;
