export default {
    ERROR_DEFAULT:
        "Došlo je do greške, molimo pokušajte ponovo ili nas kontaktirajte!",
    "400": {
        title: "Greška 400 Bad Request",
        subject:
            "Client sent an invalid request — such as lacking required request body or parameter",
    },
    "401": {
        title: "Greška 401 Unauthorized",
        subject: "Client failed to authenticate with the server",
    },
    "403": {
        title: "Greška 403 Forbidden",
        subject:
            "Client authenticated but does not have permission to access the requested resource",
    },
    "404": {
        title: "Greška 404 Not Found",
        subject: "The requested resource does not exist",
    },
    "409": {
        title: "Greška 409 Conflict",
        subject:
            "Problem prilikom zakazivanja termina, molimo pokušajte ponovo ili nas kontaktirajte!",
    },
    "412": {
        title: "Greška 412 Precondition Failed",
        subject:
            "One or more conditions in the request header fields evaluated to false",
    },
    "500": {
        title: "Greška 500 Internal Server",
        subject:
            "Došlo je do greske na serveru, molimo pokušajte ponovo ili nas kontaktirajte!",
    },
    "503": {
        title: "Greška 412 Service Unavailable",
        subject: "The requested service is not available",
    },
};
