import moment from "moment";
import calendarConst from "../constants/CaledarConstants";

const datesAreSameDay = (first, second) => {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    );
};

const addDays = (days) => {
    const copy = new Date(new Date().setHours(0, 0, 0, 0));
    copy.setDate(copy.getDate() + days);
    return copy;
};

const addTimeMiliToDate = (date, timeMili) => {
    return new Date(date.getTime() + timeMili);
};

const calendarTimeFormat = ({ start, end }, culture, local) => {
    return (
        local.format(start, calendarConst.TIME_FORMAT_VIEW, culture) +
        " - " +
        local.format(end, calendarConst.TIME_FORMAT_VIEW, culture)
    );
};

const isSummerPeriod = (dateTimeStemp = moment()) => {
    const year = moment(dateTimeStemp).year();
    const pocinjeLetnjaSezona = moment([year, calendarConst.SUMMER_PERIOD_FROM, calendarConst.SUMMER_PERIOD_DAY_FROM]).unix();
    const zavravaLetnjaSezona = moment([year, calendarConst.SUMMER_PERIOD_TO, calendarConst.SUMMER_PERIOD_DAY_TO]).unix();
    const currentTimeStamp = dateTimeStemp.unix();
    return currentTimeStamp > pocinjeLetnjaSezona && currentTimeStamp < zavravaLetnjaSezona;
};

const dayOfWeekFun = (start) => {
    const weekday = moment(start).weekday();
    // subota je 5, nedelja je 6
    let dayOfWeek = "WORKING";  
    if (weekday === 5 || weekday  === 6) {
        dayOfWeek = "WEEKEND";
    }
    return dayOfWeek;
}

const showSummerPeriod = () => {
    const year = moment().year();
    const pocinjeLetnjaSezona = moment([year, calendarConst.SUMMER_PERIOD_FROM, calendarConst.SUMMER_PERIOD_DAY_FROM]);
    const zavravaLetnjaSezona = moment([year, calendarConst.SUMMER_PERIOD_TO, calendarConst.SUMMER_PERIOD_DAY_TO]);

    return (
        <div>
            Od <span style={{ color: '#009688' }}>{moment(pocinjeLetnjaSezona).format('D MMMM YYYY')}</span> Do <span style={{ color: '#009688' }}>{moment(zavravaLetnjaSezona).format('D MMMM YYYY')}</span>
        </div>
    );
}

const showWinterPeriod = () => {
    const year = moment().year();
    const zavrsavaseZimskiPeriod = moment([year, calendarConst.SUMMER_PERIOD_FROM, calendarConst.SUMMER_PERIOD_DAY_FROM]);
    const pocinjeZimskiPeriod = moment([year, calendarConst.SUMMER_PERIOD_TO, calendarConst.SUMMER_PERIOD_DAY_TO]);

    return (
        <>
            Do <span style={{ color: '#009688' }}>{moment(zavrsavaseZimskiPeriod).format('D MMMM YYYY')}</span> Od <span style={{ color: '#009688' }}>{moment(pocinjeZimskiPeriod).format('D MMMM YYYY')}</span>
        </>
    );
};


export { datesAreSameDay, addDays, addTimeMiliToDate, calendarTimeFormat, isSummerPeriod, dayOfWeekFun, showSummerPeriod, showWinterPeriod };
