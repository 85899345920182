import React from "react";
import moment from "moment";
import { Modal, Button, Form } from "react-bootstrap";
import { round_to_precision, getEventPrice } from "../utils/EventUtils";
import calendarConstats from "../constants/CaledarConstants";
import userConst from "../constants/UserConstants";
import userConstants from "../constants/UserConstants";

const CreateEventModel = (props) => {
    const title = "Sacuvaj Termin";
    const textForSave = "Da li želite da zakažete termin dana";
    const Hours = [1, 1.5, 2];

    const text = `${textForSave} ${moment(props.event.start).format(
        calendarConstats.DATE_FORMAT_VIEW
    )} od ${moment(props.event.start).format(
        calendarConstats.TIME_FORMAT_VIEW
    )} do ${moment(props.event.end).format(
        calendarConstats.TIME_FORMAT_VIEW
    )} na terenu ${props.event[calendarConstats.RESOURCE_ID]} ?`;

    const handleValidation = () => {
        let isValid = false;
        if (
            props.radioEvent.selected &&
            props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Player &&
            !props.radioEvent.playerUUID
        ) {
            isValid = true;
        } else if ((props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Gost || props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Liga || props.radioEvent.selected == userConstants.ROLE_EVENT_ID.LigaDouble || props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Football) && !props.radioEvent.guest ||
            (props.user.roleId == userConstants.ROLE_ID.Football || props.user.roleId == userConstants.ROLE_ID.Watcher || props.user.roleId == userConstants.ROLE_ID.League) && !props.radioEvent.guest) {
            isValid = true;
        }
        return isValid;
    };

    //Player
    const renderDropDownUserList = () => {
        const listItems = props.allUsers.map((existUser, index) => {
            if (existUser.roleId == userConst.ROLE_ID.Player) {
                return <option key={index} value={existUser.uuid}>
                    {existUser.lastName} {existUser.name} - {userConst.ROLE[existUser.roleId]} : {existUser.credit}
                </option>
            }
        });
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-3">
                    <Form.Label>Igrač</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(e) =>
                            props.handleRadio({
                                playerUUID: e.target.value,
                            })
                        }
                    >
                        <option></option>
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    };


    //Rekreativac: "Igrač Klub",
    const renderDropDownUserIgracList = () => {
            const listItems = props.allUsers.map((existUser, index) => {
                if (existUser.roleId == userConst.ROLE_ID.PlayerRekreativac) {
                    return <option key={index} value={existUser.uuid}>
                        {existUser.lastName} {existUser.name} - {userConst.ROLE[existUser.roleId]} : {existUser.credit}
                    </option>
                }
            });
            return (
                <div className="d-flex justify-content-center">
                    <Form.Group className="w-50 pb-3">
                        <Form.Label>Igrač Klub</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(e) =>
                                props.handleRadio({
                                    playerUUID: e.target.value,
                                })
                            }
                        >
                            <option></option>
                            {listItems}
                        </Form.Control>
                    </Form.Group>
                </div>
            );
        };
    
    //Trainer
    const renderDropDownTrainerList = () => {
        const listItems = props.allUsers.map((existUser, index) => {
            if (existUser.roleId == userConst.ROLE_ID.Trainer) {
                return <option key={index} value={existUser.uuid}>
                    {existUser.lastName} {existUser.name} - {userConst.ROLE[existUser.roleId]} : {existUser.credit}
                </option>
            }
        });
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-3">
                    <Form.Label>Trener Klub:</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(e) =>
                            props.handleRadio({
                                playerUUID: e.target.value,
                            })
                        }
                    >
                        <option></option>
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    };

    const renderDropDownGostTrainerList = () => {
        const listItems = props.allUsers.map((existUser, index) => {
            if (existUser.roleId == userConst.ROLE_ID.GostTrainer) {
                return <option key={index} value={existUser.uuid}>
                    {existUser.lastName} {existUser.name} - {userConst.ROLE[existUser.roleId]} : {existUser.credit}
                </option>
            }
        });
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-3">
                    <Form.Label>Trener:</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={(e) =>
                            props.handleRadio({
                                playerUUID: e.target.value,
                            })
                        }
                    >
                        <option></option>
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    };

    const renderMonthRepeat = () => {
        return (
            <>
                <h5 className="text-center align-items-center pb-2 pt-3">
                    Koliko meseci unapred:
                </h5>
                <div className="d-flex justify-content-around align-items-center pb-4 pt-2 mx-auto">
                    {[0, 1, 3, 6].map((item) => {
                        return (
                            <Form.Check
                                id={`inline-radio-month-${item}`}
                                key={`item-month-${item}`}
                                type="radio"
                                name="month"
                                inline
                                label={item}
                                checked={props.radioEvent.month == item}
                                onChange={() => {
                                    props.handleRadio({
                                        month: item,
                                    });
                                }}
                            />
                        );
                    })}
                </div>
            </>
        );
    };

    const renderEndTimeForTournement = () => {
        moment.locale(calendarConstats.LOCALE_DATE_FORMAT);
        const findClosestStart = props.getClosestStartEventForTurnament(
            props.event
        );
        const endDayMaxTime = moment(props.event.start).endOf("day").valueOf();
        const endTime = !!findClosestStart
            ? (findClosestStart.getTime() - props.event.start) /
            calendarConstats.HOUR_MILI_SEC
            : round_to_precision(
                (endDayMaxTime - props.event.start) /
                calendarConstats.HOUR_MILI_SEC,
                0.5
            );
        return (
            <>
                {[1, 2, 4, 6, 8, 10, 12, 14].map((item) => {
                    return (
                        endTime >= item && (
                            <Form.Check
                                id={`inline-radio-${item}`}
                                key={item}
                                type="radio"
                                name="hour"
                                inline
                                label={`${item}h`}
                                checked={props.radioEvent.hour == item}
                                onChange={() => {
                                    props.handleRadioEndEvent({
                                        hour: item,
                                    });
                                }}
                            />
                        )
                    );
                })}
            </>
        );
    };

    const renderHours = (item) => {
        return (
            <Form.Check
                id={`inline-radio-${item}`}
                key={item}
                type="radio"
                name="hour"
                inline
                label={`${item}h`}
                checked={props.radioEvent.hour == item}
                onChange={() => { props.handleRadioEndEvent({ hour: item }) }}
            />
        );
    };

    return (
        <Modal centered show={props.show} onHide={props.onToggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 className="text-center">{text}</h5>
                {props.isAdmin && props.radioEvent && (
                    <>
                        <h5 className="text-center align-items-center pb-0 pt-3">
                            Termin za:
                        </h5>
                        <div className="create-event-admin d-flex flex-wrap justify-content-around align-items-center pb-3 pt-2 mx-auto" >
                            <Form.Check
                                type="radio"
                                name="check"
                                id="igrac-check"
                                label="Igrač"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Player,
                                        guest: "",
                                    })
                                }
                            />
                             <Form.Check
                                type="radio"
                                name="check"
                                id="gost-trainer-check"
                                label="Trainer" 
                                //GostTrener 
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.GostTrener,
                                        guest: "",
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="igrac-klub-check"
                                label="Igrač klub"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Rekreativac,
                                        guest: "",
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="trainer-check"
                                label="Trainer Klub"
                                // Trener
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Trener,
                                        guest: "",
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="gost-check"
                                label="Gost"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Gost,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="cash-check"
                                label="Član"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.CashPlayer,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="Liga-check"
                                label="Liga Singlova"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Liga,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="LigaDublova-check"
                                label="Liga Dublova"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.LigaDouble,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="Football-check"
                                label="Football"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Football,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="Turnir-check"
                                label="Turnir"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Turnir,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="Radovi-check"
                                label="Radovi"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Radovi,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="Fiksni-check"
                                label="Fiksni"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.Fix,
                                        playerUUID: null,
                                    })
                                }
                            />
                        </div>
                        {props.radioEvent &&
                                (props.radioEvent.selected ==
                                    userConstants.ROLE_EVENT_ID.Gost ||
                                        props.radioEvent.selected ==
                                        userConstants.ROLE_EVENT_ID.Liga) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Gost / Liga Singlova"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent &&
                            props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.Football && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Fudbal"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent && props.radioEvent.selected == userConstants.ROLE_EVENT_ID.LigaDouble && (
                            <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                <Form.Control
                                    type="text"
                                    placeholder="Liga Dublova"
                                    onChange={(e) =>
                                        props.handleRadio({
                                            guest: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {props.radioEvent &&
                            (props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.CashPlayer) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Član"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent &&
                            (props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.Fix) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Fiksni"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent && props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Player && (
                            renderDropDownUserList()
                        )}
                        {props.radioEvent && props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Rekreativac && (
                            renderDropDownUserIgracList()
                        )}
                        {props.radioEvent && props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Trener && (
                            renderDropDownTrainerList()
                        )}
                         {props.radioEvent && props.radioEvent.selected == userConstants.ROLE_EVENT_ID.GostTrener && (
                            renderDropDownGostTrainerList()
                        )}
                        <h5 className="text-center align-items-center pb-0 pt-3">
                            Vreme trajanja termina
                        </h5>
                        <div className="d-flex justify-content-around align-items-center pb-3 pt-2 mx-auto">
                            {props.radioEvent && (props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Turnir || props.radioEvent.selected == userConstants.ROLE_EVENT_ID.Radovi) ?
                                renderEndTimeForTournement()
                                :
                                Hours.map(item => {
                                    return props.radioEvent.isAllowHourNextEventEnd >= item && renderHours(item)
                                })
                            }
                        </div>
                        {renderMonthRepeat()}
                    </>
                )}
                {(props.user.roleId == userConst.ROLE_ID.League) && (
                    <>
                        <div className="text-center align-items-center pb-2 pt-3">Liga</div>
                        <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                            <Form.Control
                                type="text"
                                placeholder="Liga Singlova"
                                onChange={(e) =>
                                    props.handleRadio({
                                        selected: userConstants.ROLE_EVENT_ID.Liga,
                                        guest: e.target.value,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="LigaDublova-check"
                                label="Liga Dublova"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.LigaDouble,
                                        playerUUID: null,
                                    })
                                }
                            />
                        </div>
                    </>
                )}
                {(props.user.roleId == userConst.ROLE_ID.Watcher) && (
                    <>
                        <div className="text-center align-items-center pb-2 pt-3">Gost</div>
                        <div className="d-flex justify-content-around align-items-center pb-3 pt-3 mx-auto">
                            <Form.Check
                                type="radio"
                                name="check"
                                id="gost-check"
                                label="Gost"
                                onClick={() =>
                                    props.handleRadio({
                                        selected: userConstants.ROLE_EVENT_ID.Gost,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="cash-check"
                                label="Član"
                                onClick={() =>
                                    props.handleRadio({
                                        selected:
                                            userConstants.ROLE_EVENT_ID.CashPlayer,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="credit-check"
                                label="Liga Singlova"
                                onClick={() =>
                                    props.handleRadio({
                                        selected: userConstants.ROLE_EVENT_ID.Liga,
                                        playerUUID: null,
                                    })
                                }
                            />
                            <Form.Check
                                type="radio"
                                name="check"
                                id="LigaDublova-check"
                                label="Liga Dublova"
                                onClick={() =>
                                    props.handleRadio({
                                        selected: userConstants.ROLE_EVENT_ID.LigaDouble,
                                        playerUUID: null,
                                    })
                                }
                            />
                        </div>
                        {props.radioEvent &&
                            (props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.Gost ||
                                props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.Liga) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Gost / Liga Singlova"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent &&
                            (props.radioEvent.selected == userConstants.ROLE_EVENT_ID.LigaDouble) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Liga Dublova"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                        {props.radioEvent &&
                            (props.radioEvent.selected ==
                                userConstants.ROLE_EVENT_ID.CashPlayer) && (
                                <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                                    <Form.Control
                                        type="text"
                                        placeholder="Član"
                                        onChange={(e) =>
                                            props.handleRadio({
                                                guest: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                        )}
                    </>
                )}
                {(props.user.roleId == userConst.ROLE_ID.Football) && (
                    <>
                        <div className="text-center align-items-center pb-2 pt-3">Fudbal</div>
                        <div className="d-flex justify-content-around align-items-center pb-3 mx-auto">
                            <Form.Control
                                type="text"
                                placeholder="Football"
                                onChange={(e) =>
                                    props.handleRadio({
                                        selected: userConstants.ROLE_EVENT_ID.Football,
                                        guest: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </>
                )}
                {(!props.isAdmin && props.user.credit >= 1 && props.radioEvent.isAllowHour >= 1 && props.radioEvent.isAllowHourNextEventEnd >= 1) && (
                    <>
                        <h5 className="text-center align-items-center pb-0 pt-3">
                            Vreme trajanja termina
                        </h5>
                        <div className="d-flex justify-content-around align-items-center pb-3 pt-2 mx-auto">
                            {Hours.map((item) => {
                                return props.radioEvent.isAllowHour >= item && props.radioEvent.isAllowHourNextEventEnd >= item && props.user.credit >= getEventPrice(props.event.start, (props.event.start +  (item * calendarConstats.HOUR_MILI_SEC)), props.event.roleEventID, props.zonaTime, props.event, props.user) && renderHours(item)
                                // return props.radioEvent.isAllowHour >= item && props.radioEvent.isAllowHourNextEventEnd >= item && props.user.credit >= item && renderHours(item)
                            })}
                        </div>
                    </>
                )}
                <h5 className="text-center align-items-center pb-0 pt-3">
                    Cena Termina: {props.event?.start > 0 && getEventPrice(props.event.start, (props.event.start +  (props.radioEvent.hour * calendarConstats.HOUR_MILI_SEC)), props.radioEvent.selected, props.zonaTime, props.event, props.user)}
                </h5>
            </Modal.Body>
            <Modal.Footer>
                <h5 className="text-center ml-sm-5 mr-sm-5 pb-1">Nakon potvrde rezervacije termina, molimo Vas proverite da li je termin upisan u rasporedu.</h5>
                <div className="d-flex w-100 justify-content-between">
                    <Button
                        disabled={handleValidation()}
                        className={handleValidation() ? 'bg-dark' : 'bg-secondary'}
                        onClick={() => { props.handleSave() }}
                    >
                        Da
                    </Button>
                    <Button onClick={props.onToggleModal}>Ne</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
export default CreateEventModel;
