import React, { Component } from "react";
import userConst from "../constants/UserConstants";
import { isEmpty, find } from "lodash";
import { getRoleEventTitle } from "../utils/UserUtils";

class CustomEvent extends Component {
    constructor(props) {
        super(props);
        this.getDisplayName = this.getDisplayName.bind(this);
    }

    getDisplayName() {
        const { user, event, allUsers } = this.props;
        if (
            user.roleId == userConst.ROLE_ID.Admin ||
            user.roleId == userConst.ROLE_ID.Watcher ||
            user.roleId == userConst.ROLE_ID.Football ||
            user.roleId == userConst.ROLE_ID.League
        ) {
            if (
                event.roleEventID == userConst.ROLE_EVENT_ID.Football ||
                event.roleEventID == userConst.ROLE_EVENT_ID.Gost ||
                event.roleEventID == userConst.ROLE_EVENT_ID.Liga ||
                event.roleEventID == userConst.ROLE_EVENT_ID.Fix ||
                event.roleEventID == userConst.ROLE_EVENT_ID.Turnir ||
                event.roleEventID == userConst.ROLE_EVENT_ID.Radovi ||
                event.roleEventID == userConst.ROLE_EVENT_ID.CashPlayer ||
                event.roleEventID == userConst.ROLE_EVENT_ID.LigaDouble
            ) {
                // return getRoleEventTitle(event).toUpperCase();
                return getRoleEventTitle(event);
            } else {
                const findUser = find(allUsers, (exstingUser) => {
                    return exstingUser.uuid == event.userUuid;
                });
                return `${findUser.name} ${
                    findUser.lastName
                } - ${event.deleted == 2 ? "Opravdan izostanak / vraćen kredit" : getRoleEventTitle(event)}`;
            }
        } else {
            return user.uuid == event.userUuid
                ? `${user.name} ${user.lastName} - ${event.deleted == 2 ? "Opravdan izostanak / vraćen kredit" : getRoleEventTitle(event)}`
                : getRoleEventTitle(event);
        }
    }

    render() {
        const displayName = isEmpty(this.props.user)
            ? getRoleEventTitle(this.props.event)
            : this.getDisplayName();
        return (
            <div className="custom__event">
                <h6>{displayName}</h6>
            </div>
        );
    }
}

export default CustomEvent;
