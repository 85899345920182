import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Tabs, Tab, Table  } from "react-bootstrap";
import { getRoleEventTitleForSumReportMouth } from "../utils/UserUtils";
import { showSummerPeriod, showWinterPeriod } from "../utils/DateUtils";
import zona from "../constants/Zona";

class ZonaComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 1
        };

        this.handleSelect = this.handleSelect.bind(this);    
        this.renderTableRows = this.renderTableRows.bind(this);
    }
    
    renderTableRows(seasonData) {
        return seasonData.map((item, index) => (
            <tbody key={index}>
                <tr key={"header_" + index}>
                    <td className="text-center">
                        <h3 className="mt-3"><b>{item.time[0]} - {item.time[item.time.length - 1]}</b></h3>
                    </td>
                </tr>
                <tr key={"data_otvoreni" + index}>
                    <td className="d-flex justify-content-around bg-secondary text-white">
                        <h3 className="m-0">Otvoreni</h3> 
                    </td>
                </tr>
                <tr key={"data_otvoreni_result" + index}>
                    <td className="d-flex justify-content-around bg-secondary">
                        {Object.entries(item.cena.outside).map(([key, value]) => value !== 0 ? (
                            <div key={"item_" + key} className="text-center text-white">
                                <h5>{getRoleEventTitleForSumReportMouth(parseInt(key)).toUpperCase()}</h5>
                                <h5>{value}</h5>
                            </div>
                        ) : null)}
                    </td>
                </tr>
                <tr key={"data_hala_" + index}>
                    <td className="d-flex justify-content-around bg-success text-white">
                        <h3 className="m-0">Hala / Balon</h3>
                    </td>
                </tr>
                <tr key={"data_hala_result_" + index}>
                    <td className="d-flex justify-content-around bg-success">
                        {Object.entries(item.cena.indoors).map(([key, value]) => value !== 0 ? (
                            <div key={"item_" + key} className="text-center text-white">
                                <h5>{getRoleEventTitleForSumReportMouth(parseInt(key)).toUpperCase()}</h5>
                                <h5>{value}</h5>
                            </div>
                        ) : null)}
                    </td>
                </tr>
                <tr key={"data_padel_" + index}>
                    <td className="d-flex justify-content-around text-white bg-info">
                        <h3 className="m-0">Padel</h3>
                    </td>
                </tr>
                <tr key={"data_padel_result_" + index}>
                    <td className="d-flex justify-content-around bg-info">
                        {Object.entries(item.cena.padle).map(([key, value]) => value !== 0 ? (
                            <div key={"item_" + key} className="text-center text-white">
                                <h5>{getRoleEventTitleForSumReportMouth(parseInt(key)).toUpperCase()}</h5>
                                <h5>{value}</h5>
                            </div>
                        ) : null)}
                    </td>
                </tr>
            </tbody> 
        ));
    };

    handleSelect(key) {
        this.setState({ key });
    }

    render() {
        return (
            <>
                <div className="container">
                    <Tabs
                        fill 
                        activeKey={this.state.key}
                        onSelect={this.handleSelect}
                        id="controlled-tab-example"
                    >
                        <Tab eventKey={1} title={<span className="fs-3">Letnji Period</span>} >
                            <h1 className="text-center my-5">Letnji Period</h1>
                            <p className="text-center fs-2">{showSummerPeriod()}</p>
                            <div className="w-100">
                                <div className="px-3 w-100">
                                    <h2 className="text-center fs-2 text-decoration-underline">Radni Dani</h2>
                                    <Table className="table table table-sm table-striped table-bordered table-hover">
                                        {this.renderTableRows(zona.SUMMER.WORKING)}
                                    </Table>
                                </div>
                                <div className="px-3 mt-5">
                                    <h2 className="text-center fs-2 text-decoration-underline">Vikend Dani</h2>
                                    <Table className="table table table-sm table-striped table-bordered table-hover">
                                        {this.renderTableRows(zona.SUMMER.WEEKEND)}
                                    </Table>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={2} title={<span className="fs-3">Zimski Period</span>}>
                            <h1 className="text-center my-5">Zimski Period</h1>
                            <p className="text-center fs-2">{showWinterPeriod()}</p>
                            <div className="w-100">
                                <div className="px-3 w-100">
                                    <h2 className="text-center fs-2 text-decoration-underline">Radni Dani</h2>
                                    <Table className="table table table-sm table-striped table-bordered table-hover">
                                        {this.renderTableRows(zona.WINTER.WORKING)}
                                    </Table>
                                </div>
                                <div className="px-3 mt-5">
                                    <h2 className="text-center fs-2 text-decoration-underline">Vikend Dani</h2>
                                    <Table className="table table table-sm table-striped table-bordered table-hover">
                                        {this.renderTableRows(zona.WINTER.WEEKEND)}
                                    </Table>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </>
        )
    }
};

export default ZonaComponent;

if (document.getElementById("zona")) {
    ReactDOM.render(<ZonaComponent />, document.getElementById("zona"));
}
