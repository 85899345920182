import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import CalendarToolbar from "./CalendarToolbar";
import CustomEvent from "./CustomEventComponent";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Const
import calendarConst from "../constants/CaledarConstants";
//Utils
import moment from "moment";
import { getErrorState } from "../utils/ErrorHandler";
import { calendarTimeFormat, isSummerPeriod, dayOfWeekFun } from "../utils/DateUtils";
import { getCalendarEvents } from "../utils/ObjectUtils";
import { getBackgroundColor, handleCloseAlert } from "../utils/EventUtils";
//API
import { getGuestEvents } from "../api/eventsApi";
//Style
import "react-big-calendar/lib/sass/styles.scss";

const localizer = momentLocalizer(moment);

class GuestCalendarComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            isSummer: true,
            allEvents: [],
            error: {
                show: false,
                status: 0,
            },
            spinner: true,
            calendar: {
                step: 30,
                selectable: false,
                resourceIdAccessor: calendarConst.RESOURCE_ID,
                resourceTitleAccessor: calendarConst.RESOURCE_TITLE,
                views: [Views.DAY, Views.WEEK, Views.AGENDA],
                defaultView: Views.DAY,
                currentDate: new Date(),
                minHourOfDay: new Date(0, 0, 0, 7, 0, 0),
                maxHourOfDay: new Date(0, 0, 0, 23, 59, 0),
            },
        };
        this.getErrorState = getErrorState.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);
    }

    componentDidMount() {
        const isSummer = isSummerPeriod();
        moment.locale(calendarConst.LOCALE_DATE_FORMAT);
        getGuestEvents().then((response) => {
            const stateObj = !!response.status
                ? this.getErrorState(response)
                : {
                      allEvents: [...getCalendarEvents(response)],
                  };

            this.setState({
                isSummer: isSummer,
                spinner: false,
                ...stateObj,
            });
        });
    }

    render() {
        const { calendar, allEvents, spinner, isSummer, error } = this.state;
        const winterClass = isSummer ?  "" : "winter-zone"
        const dayOfWeekClass = dayOfWeekFun(new Date()) == "WEEKEND" ? "weekend" : "";
        return (
            <div className={`container-fluid pr-1 pr-sm-3 pl-sm-3 pl-1 calendar hala-column ${winterClass} ${dayOfWeekClass}`}>
                <SpinnerComponents show={spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={error}
                />
                <Calendar
                    formats={{
                        timeGutterFormat: "HH", // "HH:mm"
                        eventTimeRangeFormat: calendarTimeFormat,
                        selectRangeFormat: calendarTimeFormat,
                    }}
                    events={allEvents}
                    localizer={localizer}
                    views={calendar.views}
                    defaultView={calendar.defaultView}
                    step={calendar.step}
                    selectable={calendar.selectable}
                    min={calendar.minHourOfDay}
                    max={calendar.maxHourOfDay}
                    defaultDate={calendar.currentDate}
                    resources={calendarConst.RESOURCE_MAP}
                    resourceIdAccessor={calendar.resourceIdAccessor}
                    resourceTitleAccessor={calendar.resourceTitleAccessor}
                    components={{
                        toolbar: (props) => (
                            <CalendarToolbar
                                {...props}
                                user={{}}
                                calendar={calendar}
                            />
                        ),
                        event: (props) => (
                            <CustomEvent {...props} user={{}} allUsers={[]} />
                        ),
                    }}
                    eventPropGetter={(event) => {
                        const backgroundColor = getBackgroundColor(event, this.state);
                        return { style: { backgroundColor } };
                    }}
                />
            </div>
        );
    }
}

export default GuestCalendarComponent;

if (document.getElementById("guestCalendar")) {
    ReactDOM.render(
        <GuestCalendarComponent />,
        document.getElementById("guestCalendar")
    );
}
