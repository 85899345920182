import errorConst from "../constants/ErrorConstants";

export function errorHandler(status) {
    switch (status) {
        case 400:
        case 401:
        case 403:
        case 404:
        case 409:
        case 412:
        case 500:
        case 503:
            return errorConst[status];
            break;

        default:
            return errorConst.ERROR_DEFAULT;
    }
}

export function getErrorState(response) {
    return {
        error: {
            ...this.state.error,
            ...{
                show: true,
                status: response.status,
            },
        },
    };
}
