import calendarConst from "../constants/CaledarConstants";
import { getRoleEventIDForUserRoleId } from "../utils/UserUtils";
import userConst from "../constants/UserConstants";
import { dayOfWeekFun } from "./DateUtils";
import _, { round } from "lodash";
import moment from "moment";

const getEventsForUUID = (events, uuid) => {
    return _.filter(events, (event) => {
        return event.userUuid == uuid;
    });
};

const isStartTimeAfterCurent = (event) => {
    return (
        event.start >
        moment().add(calendarConst.BOOKING_EVENT_LEAST_IN_MINUTES, "m")
    );
};

const isEventInPast = (event) => {
    return (
        event.end < moment()
    );
};

const getEventCredit = (event) => {
    return (event.end - event.start) / calendarConst.HOUR_MILI_SEC;
};

const getEventsForDay = (event, allEvents) => {
    return allEvents.filter((exstingEvent) => {
        return (
            exstingEvent.deleted == 0 &&
            moment(event.start).isSame(exstingEvent.start, "day")
        );
    });
};

const round_to_precision = (x, precision) => {
    var y = +x + (precision === undefined ? 0.5 : precision / 2);
    return y - (y % (precision === undefined ? 1 : +precision));
};

function getBackgroundColor(event, state) {
    if (event.deleted == 2) {
        return calendarConst.COLORS.EVENT.delete;
    } else if (event.end < moment() && !state?.isAdmin) {
        return calendarConst.COLORS.EVENT.past;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Liga) {
        return calendarConst.COLORS.EVENT.league;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.LigaDouble) {
        return calendarConst.COLORS.EVENT.ligaDublova;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Turnir) {
        return calendarConst.COLORS.EVENT.tournament;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Radovi) {
        return calendarConst.COLORS.EVENT.radovi;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.CashPlayer) {
        return calendarConst.COLORS.EVENT.cashplayer;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Fix) {
        return calendarConst.COLORS.EVENT.fix;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Gost) {
        return calendarConst.COLORS.EVENT.guest;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Football) {
        return calendarConst.COLORS.EVENT.football;
    } else if (state?.user && event.userUuid == state.user.uuid) {
        return calendarConst.COLORS.EVENT.logdin;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Trener) {
        return calendarConst.COLORS.EVENT.active;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.Rekreativac) {
        return calendarConst.COLORS.EVENT.rekretivac;
    } else if (event.roleEventID == userConst.ROLE_EVENT_ID.GostTrener) {
        return calendarConst.COLORS.EVENT.gosttrener;
    } else {
        return calendarConst.COLORS.blue;
    }
}

function getMaxDayTerminForUser(event) {
    return getEventsForDay(event, this.state.allEvents).reduce(
        (acc, exstingEvent) => {
            return exstingEvent.userUuid == this.state.user.uuid
                ? acc + getEventCredit(exstingEvent)
                : acc;
        },
        0
    );
}

function getEventsForResourceId(event) {
    return this.state.allEvents.filter((exstingEvent) => {
        return (
            exstingEvent.deleted != 1 &&
            event.resourceId == exstingEvent.resourceId &&
            (exstingEvent.end > moment() || this.state.isAdmin) &&
            moment(event.start).isSame(exstingEvent.start, "day")
        );
    });
}

function isAllowedEventStart(event) {
    const startTime = moment(event.start).format(calendarConst.TIME_FORMAT_VIEW);
    if(startTime == "23:30") {
        return false;
    }

    return !this.getEventsForResourceId(event).find((resourceEvents) => {
        return (
            (event.start >=
                new Date(
                    resourceEvents.start.getTime() -
                    calendarConst.MIN_START_GAP_TIME_MILI_SEC
                ) &&
                event.start < resourceEvents.end) ||
            (new Date(
                event.start.getTime() - calendarConst.MIN_END_GAP_TIME_MILI_SEC
            ) >= resourceEvents.end &&
                new Date(
                    event.start.getTime() -
                    calendarConst.MAX_END_GAP_TIME_MILI_SEC
                ) < resourceEvents.end)
        );
    });
}

function getClosestStartEventForTurnament(event) {
    return this.getEventsForResourceId(event)
        .filter((exstingEvent) => {
            return event.start < exstingEvent.start;
        })
        .sort((a, b) => {
            return a.start < b.start ? -1 : 1;
        })[0]?.start;
}

function getClosestStartEvent(event) {
    return this.getEventsForResourceId(event)
        .filter((exstingEvent) => {
            return (
                event.end > exstingEvent.start &&
                event.start < exstingEvent.start
            );
        })
        .sort((a, b) => {
            return a.start < b.start ? -1 : 1;
        })[0]?.start;
}

function getEventEnd(event) {
    const endDayMaxTime = moment(event.start).endOf("day").valueOf();
    const findClosestStart = this.getClosestStartEvent(event);
    const diff_hour =
        (!!findClosestStart
            ? findClosestStart.getTime()
            : event.end.getTime()) - event.start.getTime();

    if (diff_hour <= calendarConst.MIN_EVENT_TIME_MILI_SEC) {
        return event.start.getTime() + calendarConst.MIN_EVENT_TIME_MILI_SEC >
            endDayMaxTime
            ? new Date(endDayMaxTime)
            : new Date(
                event.start.getTime() + calendarConst.MIN_EVENT_TIME_MILI_SEC
            );
    } else if (diff_hour > calendarConst.MAX_EVENT_TIME_MILI_SEC) {
        return new Date(
            event.start.getTime() + calendarConst.MAX_EVENT_TIME_MILI_SEC
        );
    } else {
        return findClosestStart || event.end;
    }
}

function isAllowEventEndTime(selectedEvent) {
    const end = selectedEvent.start + calendarConst.MAX_EVENT_TIME_MILI_SEC;
    const endDayMaxTime = moment(this.state.selectedEvent.start)
        .endOf("day")
        .valueOf();
    const findClosestStart = this.getEventsForResourceId(selectedEvent)
        .filter((exstingEvent) => {
            return (
                end > exstingEvent.start &&
                selectedEvent.start < exstingEvent.start
            );
        })
        .sort((a, b) => {
            return a.start < b.start ? -1 : 1;
        })[0]?.start;

    if (!!findClosestStart) {
        const diff_hour = findClosestStart.getTime() - selectedEvent.start;
        return diff_hour / calendarConst.HOUR_MILI_SEC;
    } else if ((endDayMaxTime - selectedEvent.start) / calendarConst.HOUR_MILI_SEC < 2) {
        return round_to_precision(
            (endDayMaxTime - selectedEvent.start) / calendarConst.HOUR_MILI_SEC,
            0.5
        );
    } else {
        return (
            calendarConst.MAX_EVENT_TIME_MILI_SEC / calendarConst.HOUR_MILI_SEC
        );
    }
}

function isAllowDeleteEvent(event) {
    return (
        event.deleted == 0 &&
        (this.state.isAdmin ||
            (event.userUuid == this.state.user.uuid &&
                moment(event.start) >
                moment().add(this.state.cancelationTime, "hours")))
    );
}

function handleCloseAlert() {
    this.setState({
        error: {
            ...this.state.error,
            ...{ show: false },
        },
    });
    window.location.reload(false);
}

function getNameForteren(terenId) {
    let name = "outside";
    if (terenId == 4 || terenId == 5 || terenId == 8) {
        name = "indoors";
    } else if (terenId == 9) {
        name = "padle";
    } else if (terenId == 6) {
        name = "football";
    }
    return name;
}

const getEventPrice = (start, end, roleEventID, zonaTime, termin, user, withZona = false) => {
    if(!roleEventID) {
        roleEventID = getRoleEventIDForUserRoleId(user.roleId);
    }

    let cenaTermina = 0;
    let indexStartTime = -1;
    let indexEndTime = -1;
    let indexStartArray;
    let indexEndArray;
    let terminZonaStart = 0;
    let terminZonaEnd = 0;
    const terenName = getNameForteren(termin.resourceId);
    const startTime = moment(start).format(calendarConst.TIME_FORMAT_VIEW);
    const endTime = moment(end).format(calendarConst.TIME_FORMAT_VIEW);
    const dayOfWeek = dayOfWeekFun(start);
    zonaTime[dayOfWeek].map((item, index) => {
        if (indexStartTime == -1 && item.time.indexOf(startTime) !== -1) {
            indexStartArray = index;
            indexStartTime = item.time.indexOf(startTime);
        }

        if (indexEndTime == -1 && item.time.indexOf(endTime) !== -1) {
            indexEndArray = index;
            indexEndTime = item.time.indexOf(endTime);
        }
    });
   
    const zonaEndCena = zonaTime[dayOfWeek][indexEndArray];
    const zonaStart = zonaTime[dayOfWeek][indexStartArray];

    const cenaZaTerminEnd = (Boolean(zonaEndCena.cena[terenName][roleEventID]) || zonaEndCena.cena[terenName][roleEventID] == 0) ? zonaEndCena.cena[terenName][roleEventID] : zonaEndCena.cena[terenName][userConst.ROLE_EVENT_ID.Player];
    
    if((roleEventID == userConst.ROLE_EVENT_ID.Turnir || roleEventID == userConst.ROLE_EVENT_ID.Radovi) && withZona) {
        return { cenaTermina, zonaSum: getEventCredit({ start, end }).toFixed(1)}
    } else if (indexStartArray === indexEndArray || (indexStartTime + 1) === zonaStart.time.length) {
        terminZonaEnd = getEventCredit({ start, end }).toFixed(1);
        cenaTermina = terminZonaEnd * cenaZaTerminEnd;
    } else {
        let cenaZaTerminStart = Boolean(zonaStart.cena[terenName][roleEventID]) || zonaStart.cena[terenName][roleEventID] == 0 ?
            zonaStart.cena[terenName][roleEventID] :
            zonaStart.cena[terenName][userConst.ROLE_EVENT_ID.Player];

        terminZonaStart = (zonaStart.time.length - (indexStartTime + 1)) / 2;
        terminZonaEnd = indexEndTime / 2;
        cenaTermina += terminZonaStart * cenaZaTerminStart;
        cenaTermina += terminZonaEnd.toFixed(1) * cenaZaTerminEnd;
    }

    if( withZona ) {
        if(terminZonaStart) {
            return { cenaTermina, [zonaEndCena.name]: terminZonaEnd, [zonaStart.name]: terminZonaStart, zonaSum: (terminZonaEnd + terminZonaStart)}
        } else {
            return { cenaTermina, [zonaEndCena.name]: terminZonaEnd, zonaSum: terminZonaEnd}
        }
    } else {
        return cenaTermina;
    }
}

export {
    getEventsForUUID,
    getEventEnd,
    getEventsForResourceId,
    isAllowedEventStart,
    isStartTimeAfterCurent,
    getEventCredit,
    getEventsForDay,
    getMaxDayTerminForUser,
    getBackgroundColor,
    getClosestStartEvent,
    isEventInPast,
    isAllowEventEndTime,
    isAllowDeleteEvent,
    handleCloseAlert,
    round_to_precision,
    getClosestStartEventForTurnament,
    getEventPrice,
};
