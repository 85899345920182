export default {
    RESOURCE_ID: "resourceId",
    RESOURCE_TITLE: "resourceTitle",
    MAX_TERMIN_PER_DAY: 2,
    MAX_CREDIT_PER_DAY: 2,
    MAX_CREDIT_TRENER_PER_DAY: 16,
    MAX_CREDIT_GOST_TRENER_PER_DAY: 10,
    MAX_CREDIT_WATCHER_PER_DAY: 50,
    MAX_CREDIT_FOOTBALL_PER_DAY: 50,
    MIN_CREDIT_TO_CREATE_EVENT: 1,
    MAX_VISIBLE_NEXT_DAY: 6,
    MIN_VISIBLE_PREV_DAY: 1, // 1 for today, 0 for prev day, -1 two days ago
    MAX_VISIBLE_WATCHER_NEXT_DAY: 27,
    MIN_VISIBLE_WATCHER_PREV_DAY: 1, // 1 for today, 0 for prev day, -1 two days ago
    MAX_VISIBLE_TRAINER_NEXT_DAY: 20,
    MIN_VISIBLE_GOST_TRAINER_PREV_DAY: 1, // 1 for today, 0 for prev day, -1 two days ago
    MAX_VISIBLE_GOST_TRAINER_NEXT_DAY: 13,
    MIN_VISIBLE_TRAINER_PREV_DAY: 1, // 1 for today, 0 for prev day, -1 two days ago
    MAX_VISIBLE_FOOTBALL_NEXT_DAY: 180,
    MIN_VISIBLE_FOOTBALL_PREV_DAY: 1, // 1 for today, 0 for prev day, -1 two days ago
    ONE_DAY_MILI_SEC: 86400000,
    TWO_HOUR_MILI_SEC: 7200000,
    HOUR_AND_HELF_MILI_SEC: 5400000,
    HOUR_MILI_SEC: 3600000,
    HELF_HOUR_MILI_SEC: 1800000,
    MAX_EVENT_TIME_MILI_SEC: 7200000, // TWO_HOUR_MILI_SEC
    MIN_EVENT_TIME_MILI_SEC: 3600000, // HOUR_MILI_SEC
    MIN_START_GAP_TIME_MILI_SEC: 1800000, // HELF_HOUR_MILI_SEC
    MIN_END_GAP_TIME_MILI_SEC: 1800000, // HELF_HOUR_MILI_SEC
    MAX_END_GAP_TIME_MILI_SEC: 3600000, // HOUR_MILI_SEC
    BOOKING_EVENT_LEAST_IN_MINUTES: 60,
    BOOKING_EVENT_MOST_IN_MINUTES: 120,
    SUMMER_TIME_CANCELLATION: 24, //summer 2h in hour
    WINTER_TIME_CANCELLATION: 24, //winter 20h in hour
    SUMMER_PERIOD_DAY_FROM: 1, //od za dan
    SUMMER_PERIOD_DAY_TO: 1, //od za dan
    SUMMER_PERIOD_FROM: 3, //od april
    SUMMER_PERIOD_TO: 10, //doNovembra
    LOCALE_DATE_FORMAT: "sr",
    TIME_FORMAT_VIEW: "HH:mm",
    DATE_FORMAT_VIEW: "D MMM YYYY",
    DATE_FORMAT_VIEW_WITH_DAY_OF_WEEK: "dddd, D MMM YYYY",
    DATE_FORMAT_VIEW_AND_TIME: "Do MMM YYYY, HH:mm",
    RESOURCE_MAP: [
        { resourceId: 1, resourceTitle: "Teren 1" },
        { resourceId: 2, resourceTitle: "Teren 2" },
        { resourceId: 3, resourceTitle: "Teren 3" },
        { resourceId: 4, resourceTitle: "Teren 4 (Hala)" },
        { resourceId: 5, resourceTitle: "Teren 5 (Hala)" },
        // { resourceId: 7, resourceTitle: "Teren 6" },
        // { resourceId: 8, resourceTitle: "Teren 7 (Hala)" },
        // { resourceId: 9, resourceTitle: "Teren 8 (Padel)" },
    ],
    RESOURCE_MAP_ADMIN: [
        { resourceId: 1, resourceTitle: "Teren 1" },
        { resourceId: 2, resourceTitle: "Teren 2" },
        { resourceId: 3, resourceTitle: "Teren 3" },
        { resourceId: 4, resourceTitle: "Teren 4 (Hala)" },
        { resourceId: 5, resourceTitle: "Teren 5 (Hala)" },
        // { resourceId: 7, resourceTitle: "Teren 6" },
        // { resourceId: 8, resourceTitle: "Teren 7 (Hala)" },
        // { resourceId: 9, resourceTitle: "Teren 8 (Padel)"},
        { resourceId: 6, resourceTitle: "Fudbal Teren" },
    ],
    RESOURCE_MAP_Football: [
        { resourceId: 6, resourceTitle: "Fudbal Teren" },
    ],
    COLORS: {
        blue: "#265985",
        indigo: "#6574cd",
        purple: "#9561e2",
        pink: "#f66d9b",
        red: "#e3342f",
        grey: "#6c757d",
        orange: "#f6993f",
        yellow: "#ffed4a",
        green: "#38c172",
        teal: "#88BDBC",
        cyan: "#6cb2eb",
        EVENT: {
            delete: "#231f20",
            past: "#6c747d",
            active: "#2595bf",
            logdin: "#2e6f52",
            approved: "#545454",
            guest: "#e3342f",
            league: "#ff7238",
            tournament: "#cdc40d",
            football: "#009688",
            rekretivac: "#4dc0b5",
            gosttrener: "#673ab7",
            radovi: "#6c757d",
            cashplayer: "#910a08",
            ligaDublova: "#ff9800",
            fix: "#f06292"
        },
    },
};
