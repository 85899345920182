import React, { Component } from "react";
import moment from "moment";
import Moment from "react-moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { CaretRightFill, CaretLeftFill } from "react-bootstrap-icons";
import { datesAreSameDay, addDays } from "../utils/DateUtils";

import calendarConstats from "../constants/CaledarConstants";
import bootstrapConstats from "../constants/BootstrapConstants";
import userConst from "../constants/UserConstants";

import { getRoleEventIDForUserRoleId } from "../utils/UserUtils";

class CalendarToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNotAllDaysVisible:
                props.user && props.user.roleId != userConst.ROLE_ID.Admin,
            user: props.user,
            zonaTime: props.zonaTime,
            dayOfWeek: props.dayOfWeek,
            minVisiblePrevDay:
                props.user.roleId == userConst.ROLE_ID.Trainer
                    ? calendarConstats.MIN_VISIBLE_TRAINER_PREV_DAY :
                props.user.roleId == userConst.ROLE_ID.GostTrainer
                    ? calendarConstats.MIN_VISIBLE_GOST_TRAINER_PREV_DAY :
                props.user.roleId == userConst.ROLE_ID.Watcher ? 
                    calendarConstats.MIN_VISIBLE_WATCHER_PREV_DAY :
                props.user.roleId == userConst.ROLE_ID.Football ? 
                    calendarConstats.MIN_VISIBLE_FOOTBALL_PREV_DAY
                : calendarConstats.MIN_VISIBLE_PREV_DAY,
            maxVisibleNextDay:
                props.user.roleId == userConst.ROLE_ID.Trainer
                    ? calendarConstats.MAX_VISIBLE_TRAINER_NEXT_DAY :
                props.user.roleId == userConst.ROLE_ID.GostTrainer
                    ? calendarConstats.MAX_VISIBLE_GOST_TRAINER_NEXT_DAY :
                props.user.roleId == userConst.ROLE_ID.Watcher ?
                    calendarConstats.MAX_VISIBLE_WATCHER_NEXT_DAY :
                props.user.roleId == userConst.ROLE_ID.Football ? 
                    calendarConstats.MAX_VISIBLE_FOOTBALL_NEXT_DAY
                : calendarConstats.MAX_VISIBLE_NEXT_DAY,
        };

        this.goToBack = this.goToBack.bind(this);
        this.goToNext = this.goToNext.bind(this);
        this.goToCurrent = this.goToCurrent.bind(this);
        this.isButtonDisabled = this.isButtonDisabled.bind(this);
    }

    componentDidMount() {
        moment.locale(calendarConstats.LOCALE_DATE_FORMAT);
    }

    goToBack() {
        this.props.date.setDate(this.props.date.getDate() - 1);
        this.props.onNavigate("prev");
    }

    goToNext() {
        this.props.date.setDate(this.props.date.getDate() + 1);
        this.props.onNavigate("next");
    }

    goToCurrent() {
        !datesAreSameDay(new Date(), this.props.date) &&
            this.props.onNavigate("current", new Date());
    }

    isButtonDisabled(visibleDay) {
        return this.props.date.getTime() > addDays(visibleDay).getTime();
    }

    render() {
        const {
            isNotAllDaysVisible,
            user,
            minVisiblePrevDay,
            maxVisibleNextDay,
            zonaTime,
            dayOfWeek
        } = this.state;
       const roleEventID = getRoleEventIDForUserRoleId(user.roleId);
        return (
            <>
                {this.props.calendar.selectable && <div className="calendar__toolbar-zona">
                    {zonaTime[dayOfWeek]?.map((item, index) => {
                        return (item.class && <div key={`zona_${index + 1}`} className={`price-zona ${item.class}`} >
                            <span >Otvoreni: {item.cena.outside[roleEventID]}</span>
                            <span >Hala: {item.cena.indoors[roleEventID]}</span>
                            {/* <span >Padel: {item.cena.padle[roleEventID]}</span> */}
                        </div>)
                    })}
                </div>}
                <div
                    className={`calendar__toolbar ${
                        this.props.calendar.selectable ||
                        "calendar__toolbar--center"
                    }`}
                >
                    {this.props.calendar.selectable && (
                        <div>
                            <h4 className="text-capitalize pb-md-0 pt-0">
                                Korisnik:
                                <span className="text-capitalize text-secondary font-weight-bold">
                                    {` ${user["name"]} ${user["lastName"]}`}
                                </span>
                            </h4>
                            <h5 className="mb-md-0">
                                Preostalo Kredita:
                                <span className="text-capitalize text-secondary font-weight-bold">
                                    {user["credit"]}
                                </span>
                            </h5>
                        </div>
                    )}
                    <h3 className="mb-md-0">
                        <Moment
                            format={
                                calendarConstats.DATE_FORMAT_VIEW_WITH_DAY_OF_WEEK
                            }
                        >
                            {moment(this.props.date)}
                        </Moment>
                    </h3>
                    {this.props.calendar.selectable && (
                        <div className="btn-group" role="group">
                            <button
                                type="button"
                                className={`btn ${bootstrapConstats.CALENDAR_BUTTON_OUTLINE}`}
                                onClick={this.goToBack}
                                disabled={
                                    isNotAllDaysVisible &&
                                    !this.isButtonDisabled(minVisiblePrevDay)
                                }
                            >
                                <CaretLeftFill />
                            </button>

                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-bottom">
                                        {moment().format(
                                            calendarConstats.DATE_FORMAT_VIEW
                                        )}
                                    </Tooltip>
                                }
                            >
                                <button
                                    type="button"
                                    className={`btn ${bootstrapConstats.CALENDAR_BUTTON}`}
                                    data-toggle="tooltip"
                                    onClick={this.goToCurrent}
                                >
                                    Today
                                </button>
                            </OverlayTrigger>

                            <button
                                type="button"
                                className={`btn ${bootstrapConstats.CALENDAR_BUTTON_OUTLINE}`}
                                onClick={this.goToNext}
                                disabled={
                                    isNotAllDaysVisible &&
                                    this.isButtonDisabled(maxVisibleNextDay)
                                }
                            >
                                <CaretRightFill />
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default CalendarToolbar;

// {
/* Calendar View 
<div className="rbc-btn-group">
    <button
        type="button"
        className={`btn ${bootstrapConstats.BUTTON_OUTLINE} btn-sm`}
        onClick={() => this.props.onView("day")}
    >
        Day View
    </button>
    <button
        type="button"
        className={`btn ${bootstrapConstats.BUTTON_OUTLINE} btn-sm`}
        onClick={() => this.props.onView("week")}
    >
        Week View
    </button>
    <button
        type="button"
        className={`btn ${bootstrapConstats.BUTTON_OUTLINE} btn-sm`}
        onClick={() => this.props.onView("agenda")}
    >
        Agenda View
    </button>
</div> */
// }
