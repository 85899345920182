import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Form, Button, Table } from "react-bootstrap";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Utils
import { getErrorState } from "../utils/ErrorHandler";
//API
import { handleCloseAlert } from "../utils/EventUtils";
import { postNews, getAllNews, deleteNews, updateNews } from "../api/newsApi";

class NewsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allNews: [],
            news: {
                id: null,
                title: "",
                text: "",
            },
            radio: "news",
            error: {
                show: false,
                status: 0,
            },
            spinner: true,
        };
        this.getErrorState = getErrorState.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.spinnerOn = this.spinnerOn.bind(this);
        this.updateState = this.updateState.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.renderTableNews = this.renderTableNews.bind(this);
        this.handleChangeText = this.handleChangeText.bind(this);
    }

    componentDidMount() {
        getAllNews().then((response) => {
            this.updateState(response);
        });
    }

    updateState(response) {
        const stateObj = !!response.status
            ? this.getErrorState(response)
            : {
                  allNews: [...(response?.allNews || [])],
                  news: {
                      id: null,
                      title: "",
                      text: "",
                  },
              };

        this.setState({
            spinner: false,
            ...stateObj,
        });
    }

    handleChangeText(e) {
        this.setState({
            news: {
                ...this.state.news,
                [e.target.name]: e.target.value,
            },
        });
    }

    spinnerOn() {
        this.setState({
            spinner: true,
        });
    }

    onSubmit(id) {
        this.spinnerOn();
        let newsApi = null;
        let news = this.state.news;
        if (id) {
            news.id = id;
            newsApi = updateNews(news);
        } else {
            newsApi = postNews(news);
        }

        newsApi.then((response) => {
            this.updateState(response);
        });
    }

    onDelete(newsId) {
        this.spinnerOn();
        deleteNews(newsId).then((response) => {
            this.updateState(response);
        });
    }

    handleUpdate(news) {
        this.setState({
            radio: "create",
            news,
        });
    }

    onRadioChange(radio) {
        this.setState({
            radio,
            news: {
                id: null,
                title: "",
                text: "",
            },
        });
    }

    renderTableNews() {
        return this.state.allNews.map((existNews, index) => {
            const { id, title, text } = existNews;

            return (
                <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{title}</td>
                    <td>{text}</td>
                    <td className="d-flex justify-content-around">
                        <Button
                            onClick={() => {
                                this.handleUpdate(existNews);
                            }}
                        >
                            Update
                        </Button>
                        <Button
                            className="bg-danger"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        "Are you sure you wish to delete this item?"
                                    )
                                )
                                    this.onDelete(id);
                            }}
                        >
                            Delete
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div className="container">
                <SpinnerComponents show={this.state.spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={this.state.error}
                />
                <div className="d-flex justify-content-between align-items-center pb-5 col-sm-5 mx-auto">
                    <Form.Check
                        type="radio"
                        name="check"
                        id="event-check"
                        label="Create / Update News"
                        checked={this.state.radio == "create"}
                        onChange={(e) => this.onRadioChange("create")}
                    />
                    <Form.Check
                        type="radio"
                        name="check"
                        id="credit-check"
                        label="All News"
                        checked={this.state.radio == "news"}
                        onChange={(e) => this.onRadioChange("news")}
                    />
                </div>
                {this.state.radio == "news" ? (
                    <Table className="table" striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Naslov</th>
                                <th>Opis</th>
                                <th>Akcija</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderTableNews()}</tbody>
                    </Table>
                ) : (
                    <>
                        <Form.Label>Vesti</Form.Label>
                        <Form.Group>
                            <Form.Label>Naslov</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder="Naslov"
                                onChange={this.handleChangeText}
                                value={this.state.news.title}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Opis</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Opis"
                                as="textarea"
                                rows="8"
                                name="text"
                                value={this.state.news.text}
                                onChange={this.handleChangeText}
                            />
                        </Form.Group>
                        <Button
                            className="bg-success w-50 ml-0 mr-0 container mt-5"
                            variant="primary"
                            type="button"
                            onClick={() => {
                                this.onSubmit(this.state.news.id);
                            }}
                        >
                            Save
                        </Button>
                    </>
                )}
            </div>
        );
    }
}

export default NewsComponent;

if (document.getElementById("news")) {
    ReactDOM.render(<NewsComponent />, document.getElementById("news"));
}
