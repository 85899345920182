import React from "react";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import calendarConstats from "../constants/CaledarConstants";
import userConst from "../constants/UserConstants";
import { getUserNameForUUID } from "../utils/UserUtils";

const DeleteEventModel = (props) => {
    const title = "Brisanje Termina";
    const textForDelete = "Da li ste sigurni da želite da obrišete termin dana";
    const executeUserName = props.event.executeUserUuid && getUserNameForUUID(props.allUsers, props.event.executeUserUuid)
    
    const text = `${textForDelete} ${moment(props.event.start).format(
        calendarConstats.DATE_FORMAT_VIEW
    )} od ${moment(props.event.start).format(
        calendarConstats.TIME_FORMAT_VIEW
    )} do ${moment(props.event.end).format(
        calendarConstats.TIME_FORMAT_VIEW
    )} na terenu ${props.event[calendarConstats.RESOURCE_ID]} ?`;

    const isShowApproveCancel = props.event.end < moment();
    return (
        <Modal centered show={props.show} onHide={props.onToggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isAdmin && (userConst.ROLE_EVENT_ID.Football ==
                        props.event.roleEventID ||
                        userConst.ROLE_EVENT_ID.Gost ==
                        props.event.roleEventID ||
                        userConst.ROLE_EVENT_ID.CashPlayer ==
                        props.event.roleEventID ||
                        userConst.ROLE_EVENT_ID.LigaDouble ==
                        props.event.roleEventID ||
                        userConst.ROLE_EVENT_ID.Liga ==
                            props.event.roleEventID) && (
                    <h5 className="text-center">Izvriso: <b>{executeUserName}</b></h5>
                )}
                <h5 className="text-center">{text}</h5>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button
                    className="bg-danger"
                    onClick={() => {
                        props.handleDelete(1);
                    }}
                >
                    Da
                </Button>
                {props.isAdmin && isShowApproveCancel && (
                    <Button
                        className="bg-dark"
                        onClick={() => {
                            props.handleDelete(2);
                        }}
                    >
                        Opravdan izostanak
                    </Button>
                )}
                {props.isAdmin &&
                    !isShowApproveCancel &&
                    (userConst.ROLE_EVENT_ID.Football ==
                        props.event.roleEventID ||
                        userConst.ROLE_EVENT_ID.Gost ==
                            props.event.roleEventID ||
                            userConst.ROLE_EVENT_ID.CashPlayer ==
                            props.event.roleEventID ||
                            userConst.ROLE_EVENT_ID.LigaDouble ==
                            props.event.roleEventID ||
                            userConst.ROLE_EVENT_ID.Liga ==
                                props.event.roleEventID) && (
                        <Button className="bg-success" onClick={() => {
                            props.openEdit();
                        }}>
                            Izmena
                        </Button>
                    )}
                <Button onClick={props.onToggleModal}>Ne</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default DeleteEventModel;
