import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import Model from "./Model";
import { Form, Button, Table } from "react-bootstrap";
import DeleteEventModel from "./DeleteEventModel";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Const
import userConst from "../constants/UserConstants";
import messageConst from "../constants/MessageConstants";
import calendarConst from "../constants/CaledarConstants";
//Utils
import moment from "moment";
import { find } from "lodash";
import { getErrorState } from "../utils/ErrorHandler";
import { handleCloseAlert, isAllowDeleteEvent } from "../utils/EventUtils";
import { getUserNameForUUID } from "../utils/UserUtils";
import { getObejctWithUniformProperties } from "../utils/ObjectUtils";
import { onToggleModel, onToggleCancelEventModel } from "../utils/ModelUtils";
import { isSummerPeriod } from "../utils/DateUtils";
//API
import { getAllCancelEvents, deleteCancelEvents } from "../api/eventsApi";

class CancelComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            cancelationTime: 2,
            events: [],
            user: {},
            allUsers: [],
            showCancelEventModel: false,
            showModel: false,
            spinner: true,
            error: {
                show: false,
                status: 0,
            },
            modelInfo: {
                title: "",
                text: "",
            },
            selectedEvent: {
                id: null,
                start: 0,
                end: 0,
                resourceId: 0,
                roleId: 0,
                credit: 0,
                guest: "",
                userUuid: "",
                roleEventID: 0,
                executeUserUuid: "",
                deleted: false,
            },
        };
        this.getErrorState = getErrorState.bind(this);
        this.onToggleModel = onToggleModel.bind(this);
        this.handleCloseAlert = handleCloseAlert.bind(this);
        this.isAllowDeleteEvent = isAllowDeleteEvent.bind(this);
        this.onToggleCancelEventModel = onToggleCancelEventModel.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.renderDropDownUserList = this.renderDropDownUserList.bind(this);
    }

    componentDidMount() {
        moment.locale(calendarConst.LOCALE_DATE_FORMAT);
        const setCancelationTime = isSummerPeriod ? calendarConst.SUMMER_TIME_CANCELLATION : calendarConst.WINTER_TIME_CANCELLATION;
        getAllCancelEvents().then((response) => {
            const stateObj = !!response.status
                ? this.getErrorState(response)
                : {
                      cancelationTime: setCancelationTime,
                      events: [...(response?.events || [])],
                      allUsers: [...(response?.users || [])],
                      user: { ...response?.user } || {},
                      isAdmin:
                          response.user &&
                          response.user.roleId == userConst.ROLE_ID.Admin,
                  };

            this.setState({
                spinner: false,
                ...stateObj,
            });
        });
    }

    handleChange(e) {
        this.setState({
            selectUUID: e.target.value,
        });
    }

    renderDropDownUserList() {
        const listItems = this.state.allUsers.map((existUser, index) => (
            <option key={index} value={existUser.uuid}>
                {existUser.lastName} {existUser.name}
            </option>
        ));
        return (
            <div className="d-flex justify-content-center">
                <Form.Group className="w-50 pb-5">
                    <Form.Label>Korisnici</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={this.handleChange}
                        value={this.state.selectUUID}
                    >
                        {listItems}
                    </Form.Control>
                </Form.Group>
            </div>
        );
    }

    handleDelete(deleteOption) {
        this.setState({
            spinner: true,
        });
        deleteCancelEvents(this.state.selectedEvent, deleteOption).then(
            (response) => {
                const stateObj = !!response.status
                    ? this.getErrorState(response)
                    : {
                          events: [...(response?.events || [])],
                          user: { ...response?.user } || {},
                      };

                this.setState({
                    showCancelEventModel: !this.state.showCancelEventModel,
                    spinner: false,
                    ...stateObj,
                });
            }
        );
    }

    handleDeleteEvent(event) {
        if (this.isAllowDeleteEvent(event)) {
            getObejctWithUniformProperties(this.state.selectedEvent, event);
            this.onToggleCancelEventModel();
        } else {
            this.setState({
                modelInfo: {
                    title: messageConst.BOOKING_TERMIN_TITLE,
                    text: messageConst.TERMIN_CLICK_IN_PAST,
                },
            });
            this.onToggleModel();
        }
    }

    // onToggleCancelEventModel() {
    //     this.setState({
    //         showCancelEventModel: !this.state.showCancelEventModel,
    //     });
    // }

    // onToggleModel() {
    //     this.setState({
    //         showModel: !this.state.showModel,
    //     });
    // }

    renderTableEvents() {
        const resourceMap = this.state.isAdmin ? calendarConst.RESOURCE_MAP_ADMIN : 
        this.state.user.roleId == userConst.ROLE_ID.Football ?  
        calendarConst.RESOURCE_MAP_Football :
        calendarConst.RESOURCE_MAP;

        return this.state.events.map((existEvent, index) => {
            const {
                start,
                end,
                resourceId,
                userUuid,
                executeUserUuid,
                credit,
                deleted,
                created_at,
                updated_at,
            } = existEvent;

            const isDelete = deleted == "1" || deleted == "2";
            const deleteText = isDelete
                ? deleted == "1"
                    ? "Da"
                    : "Opravdan izostanak / vraćen kredit"
                : "";

            return (
                <tr key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{getUserNameForUUID(this.state.allUsers, userUuid)}</td>
                    <td>
                        {moment(created_at).format(
                            calendarConst.DATE_FORMAT_VIEW_AND_TIME
                        )}
                    </td>
                    <td>
                        {`${moment(start).format(
                            calendarConst.DATE_FORMAT_VIEW
                        )} od ${moment(start).format(
                            calendarConst.TIME_FORMAT_VIEW
                        )} do ${moment(end).format(
                            calendarConst.TIME_FORMAT_VIEW
                        )}`}
                    </td>
                    <td>
                        {
                            find(resourceMap, [
                                "resourceId",
                                resourceId,
                            ]).resourceTitle
                        }
                    </td>
                    <td>{credit}</td>
                    <td>{deleteText}</td>
                    <td>
                        {isDelete &&
                            moment(updated_at).format(
                                calendarConst.DATE_FORMAT_VIEW_AND_TIME
                            )}
                    </td>
                    <td>
                        {isDelete &&
                            getUserNameForUUID(
                                this.state.allUsers,
                                executeUserUuid
                            )}
                    </td>
                    <td>
                        {!isDelete && (
                            <Button
                                className="bg-danger"
                                onClick={() => {
                                    this.handleDeleteEvent(existEvent);
                                }}
                            >
                                Izbriši
                            </Button>
                        )}
                    </td>
                </tr>
            );
        });
    }

    render() {
        const {
            modelInfo,
            showModel,
            showCancelEventModel,
            selectedEvent,
            isAdmin,
            spinner,
            error,
        } = this.state;

        return (
            <div className="container-fluid table-responsive">
                <SpinnerComponents show={spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={error}
                />
                <DeleteEventModel
                    isAdmin={isAdmin}
                    show={showCancelEventModel}
                    event={selectedEvent}
                    allUsers={this.state.allUsers}
                    handleDelete={this.handleDelete}
                    onToggleModal={this.onToggleCancelEventModel}
                />
                <Model
                    show={showModel}
                    info={modelInfo}
                    onToggleModal={this.onToggleModel}
                />
                <div className="d-flex justify-content-center pb-4">
                    <h3>Otkazivanje Termina</h3>
                </div>
                {/* {this.state.user.roleId == userConst.ROLE_ID.Admin &&
                    this.renderDropDownUserList()} */}
                <Table className="table" striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Korisnik</th>
                            <th>Kreirano</th>
                            <th>Datum Termina</th>
                            <th>Teren</th>
                            <th>Kredit</th>
                            <th>Obrisano</th>
                            <td>Uređeno</td>
                            <th>Izvršio</th>
                            <th>Akcija</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderTableEvents()}</tbody>
                </Table>
            </div>
        );
    }
}

export default CancelComponent;

if (document.getElementById("cancel")) {
    ReactDOM.render(<CancelComponent />, document.getElementById("cancel"));
}
