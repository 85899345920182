import React, { Component } from "react";
import ReactDOM from "react-dom";
//Components
import { Modal, Form } from "react-bootstrap";
import ErrorComponent from "../components/ErrorComponent";
import SpinnerComponents from "../components/SpinnerComponents";
//Const
import messageConst from "../constants/MessageConstants";
//API
import { getLoginUserInfo, updateUserPassword } from "../api/usersApi";
//Utils
import {
    isInvalidPass,
    isInvalidPassConfirm,
    createTextInputFild,
    handleChangePassword,
} from "../utils/UserUtils";
import { getErrorState } from "../utils/ErrorHandler";

class InfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            propertyFoUpdate: {},
            errors: {
                pass: false,
                password_confirm: false,
            },
            message: {
                pass: messageConst.INVALID_PASS,
                password_confirm: messageConst.INVALID_PASS_CONFIRM,
            },
            showModel: false,
            spinner: true,
            error: {
                show: false,
                status: 0,
            },
        };
        this.getErrorState = getErrorState.bind(this);
        this.createTextInputFild = createTextInputFild.bind(this);
        this.handleChangePassword = handleChangePassword.bind(this);

        this.updateState = this.updateState.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onToggleModal = this.onToggleModal.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
    }

    componentDidMount() {
        getLoginUserInfo().then((response) => {
            this.updateState(response);
        });
    }

    updateState(response) {
        const stateObj = !!response.status
            ? this.getErrorState(response)
            : {
                  user: response[0] || {},
              };

        this.setState({
            propertyFoUpdate: {},
            showModel: false,
            spinner: false,
            errors: {
                pass: false,
                password_confirm: false,
            },
            ...stateObj,
        });
    }

    handleValidation() {
        const invalidPass = isInvalidPass(this.state.propertyFoUpdate);
        const invalidPassConfirm = isInvalidPassConfirm(
            this.state.propertyFoUpdate
        );
        this.setState({
            errors: {
                pass: invalidPass,
                password_confirm: invalidPassConfirm,
            },
        });
        return !(invalidPass || invalidPassConfirm);
    }

    handleUpdate() {
        let isValid =
            this.handleValidation() &&
            this.state.propertyFoUpdate.hasOwnProperty("pass");
        isValid &&
            updateUserPassword(
                this.state.propertyFoUpdate.pass,
                this.state.user.uuid
            ).then((response) => {
                this.updateState(response);
            });
    }

    onToggleModal() {
        this.setState({
            showModel: !this.state.showModel,
            propertyFoUpdate: {},
            errors: {
                pass: false,
                password_confirm: false,
            },
        });
    }

    render() {
        return (
            <>
                <SpinnerComponents show={this.state.spinner} />
                <ErrorComponent
                    onCloseAlert={this.handleCloseAlert}
                    error={this.state.error}
                />
                <Modal show={this.state.showModel} onHide={() => {}}>
                    <Modal.Body>
                        <Form.Group className="text-center">
                            <Form.Label>
                                <h4>Trenutna Šifra - {this.state.user.pass}</h4>
                            </Form.Label>
                        </Form.Group>
                        {this.createTextInputFild({
                            title: "Nova Šifra",
                            name: "pass",
                        })}
                        {this.createTextInputFild({
                            title: "Potvrda Šifre",
                            name: "password_confirm",
                        })}
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <button
                            type="button"
                            className="btn bg-secondary text-white"
                            onClick={this.handleUpdate}
                        >
                            Izmeni
                        </button>
                        <button
                            type="button"
                            className="btn bg-dark text-white"
                            onClick={this.onToggleModal}
                        >
                            Zatvori
                        </button>
                    </Modal.Footer>
                </Modal>
                <div className="d-flex justify-content-center">
                    <div style={{ width: "90%", maxWidth: "500px" }}>
                        <div className="card-header bg-secondary text-white">
                            Podaci o korisniku
                        </div>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                {`${this.state.user.lastName} ${this.state.user.name}`}
                            </li>
                            <li className="list-group-item">{`Preostalo Kredita: ${this.state.user.credit}`}</li>
                            <li className="list-group-item">{`Datum rođenja: ${this.state.user.dateOfBirth || ''}`}</li>
                            <li className="list-group-item">{`Telefon: ${
                                this.state.user.phone || ""
                            }`}</li>
                            <li className="list-group-item">{`Email: ${this.state.user.email}`}</li>
                            <li className="list-group-item d-flex justify-content-between">
                                {`Sifra: ${this.state.user.pass}`}
                                <button
                                    type="button"
                                    className="btn bg-secondary text-white"
                                    onClick={() => {
                                        this.onToggleModal();
                                    }}
                                >
                                    Promena Šifre
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default InfoComponent;

if (document.getElementById("info")) {
    ReactDOM.render(<InfoComponent />, document.getElementById("info"));
}
