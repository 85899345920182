import React from "react";
import { Modal, Button } from "react-bootstrap";
import userConst from "../constants/UserConstants";

const Model = (props) => {
    return (
        <Modal show={props.show} onHide={props.onToggleModal}>
            <Modal.Header closeButton>
                <Modal.Title>{props.info.title ?? ""}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5 style={{ whiteSpace: "pre-wrap" }}>
                    {props.info.text ?? ""}
                </h5>
                <h5>
                    {props.info.user
                        ? `Klijent: ${props.info.user.name} ${
                              props.info.user.lastName
                          } - ${userConst.ROLE[props.info.user.roleId]}`
                        : ""}
                </h5>
            </Modal.Body>
            <Modal.Footer>
                {props.info.user && <Button onClick={props.handle}>Da</Button>}
                <Button onClick={props.onToggleModal}>Zatvori</Button>
            </Modal.Footer>
        </Modal>
    );
};
export default Model;
