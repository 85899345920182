import React from "react";
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponents = (props) => {
    return (
        props.show && (
            <>
                <div id="inner-box"></div>
                <Spinner animation="border" variant="light" />
            </>
        )
    );
};
export default SpinnerComponents;
