import userConst from "../constants/UserConstants";
// Player: "Igrač",
// Rekreativac: "Igrač Klub",
// GostTrener: "TRENER",
// Trener: "TRENER Klub", 
export default {
    SUMMER: {
        WORKING: [
            {
                obj: "first",
                name: "zonaOne",
                class: "price-zona--yellow",
                time: ["07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00",, "14:30", "15:00", "15:30", "16:00", "16:30", "17:00"],
                cena: {
                      "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1400,
                        [userConst.ROLE_EVENT_ID.Trener]: 1300,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1500,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1500,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            },
            {
                obj: "second",
                name: "zonaTwo",
                class: "price-zona--red",
                time: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59", "00:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1000,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1000,
                    },
                    "indoors" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1500,
                        [userConst.ROLE_EVENT_ID.Trener]: 1400,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1600,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1600,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            }
        ],        
        WEEKEND: [
            {
                obj: "first",
                name: "zonaOne",
                class: "price-zona--yellow",
                time: ["07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00",, "14:30", "15:00", "15:30", "16:00", "16:30", "17:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1400,
                        [userConst.ROLE_EVENT_ID.Trener]: 1300,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1500,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1500,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            },
            {
                obj: "second",
                name: "zonaTwo",
                class: "price-zona--red",
                time: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59", "00:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1000,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1000,
                    },
                    "indoors" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1500,
                        [userConst.ROLE_EVENT_ID.Trener]: 1400,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1600,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1600,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            }
        ]
    },
    WINTER:{
        WORKING: [
            {
                obj: "first",
                name: "zonaOne",
                class: "price-zona--yellow",
                time: ["07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        [userConst.ROLE_EVENT_ID.Player]: 1700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1700,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1600,
                        [userConst.ROLE_EVENT_ID.Trener]: 1600,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }    
            },
            {
                obj: "second",
                name: "zonaTwo",
                class: "price-zona--orange",
                time: ["13:00", "13:30", "14:00","14:30", "15:00", "15:30", "16:00", "16:30", "17:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 1900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1800,
                        [userConst.ROLE_EVENT_ID.Trener]: 1800,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }                
                }
                
            },
            {
                obj: "third",
                name: "zonaThree",
                class: "price-zona--red",
                time: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1000,
                        [userConst.ROLE_EVENT_ID.Trener]: 800,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1000,
                    },
                    "indoors" : {
                        // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 2100,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 2100,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 2000,
                        [userConst.ROLE_EVENT_ID.Trener]: 2000,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            },
            {
                obj: "fourth",
                name: "zonaTwo",
                time: ["21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59", "00:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 1900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1800,
                        [userConst.ROLE_EVENT_ID.Trener]: 1800,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            }
        ],
        WEEKEND: [
            {
                obj: "first",
                name: "zonaOne",
                class: "price-zona--yellow",
                time: ["07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub", 
                        [userConst.ROLE_EVENT_ID.Player]: 1700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1700,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1600,
                        [userConst.ROLE_EVENT_ID.Trener]: 1600,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }    
            },
            {
                obj: "second",
                name: "zonaTwo",
                class: "price-zona--orange",
                time: ["13:00", "13:30", "14:00","14:30", "15:00", "15:30", "16:00", "16:30", "17:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                        // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 1900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1800,
                        [userConst.ROLE_EVENT_ID.Trener]: 1800,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
                
            },
            {
                obj: "third",
                name: "zonaThree",
                class: "price-zona--red",
                time: ["17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 1000,
                        [userConst.ROLE_EVENT_ID.Trener]: 800,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1000,
                    },
                    "indoors" : {
                         // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 2100,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 2100,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 2000,
                        [userConst.ROLE_EVENT_ID.Trener]: 2000,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            },
            {
                obj: "fourth",
                name: "zonaTwo",
                time: ["21:00", "21:30", "22:00", "22:30", "23:00", "23:30", "23:59", "00:00"],
                cena: {
                    "outside" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 800,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 900,
                        [userConst.ROLE_EVENT_ID.Trener]: 700,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 900,
                    },
                    "indoors" : {
                         // Player: "Igrač",
                        // GostTrener: "TRENER",
                        // Rekreativac: "Igrač Klub",
                        // Trener: "TRENER Klub",  
                        [userConst.ROLE_EVENT_ID.Player]: 1900,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 1900,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 1800,
                        [userConst.ROLE_EVENT_ID.Trener]: 1800,
                    },
                    "padle" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    },
                    "football" : {
                        [userConst.ROLE_EVENT_ID.Rekreativac]: 0,
                        [userConst.ROLE_EVENT_ID.Gost]: 0,
                        [userConst.ROLE_EVENT_ID.Liga]: 0,
                        [userConst.ROLE_EVENT_ID.LigaDouble]: 0,
                        [userConst.ROLE_EVENT_ID.Turnir]: 0,
                        [userConst.ROLE_EVENT_ID.Radovi]: 0,
                        [userConst.ROLE_EVENT_ID.CashPlayer]: 0,
                        [userConst.ROLE_EVENT_ID.Football]: 0,
                        [userConst.ROLE_EVENT_ID.Player]: 0,
                        [userConst.ROLE_EVENT_ID.Trener]: 0,
                        [userConst.ROLE_EVENT_ID.GostTrener]: 0,
                    }
                }
            }
        ]
    }
}