import axios from 'axios';

const getAllNews = () => {
    return axios
        .get("news/all")
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const postNews = (news) => {
    return axios
        .post("news/create", {
            news,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const updateNews = (news) => {
    return axios
        .post("news/update", {
            news,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

const deleteNews = (newsId) => {
    return axios
        .post("news/delete", {
            id: newsId,
        })
        .then(function (response) {
            return response?.data || [];
        })
        .catch(function (error) {
            return error.response;
        });
};

export { getAllNews, postNews, deleteNews, updateNews };
