import caledarConst from "./CaledarConstants";
export default {
    BOOKING_TERMIN_TITLE: "Informacija",
    BOOKING_TERMIN_TEXT: `Nije moguće izvršiti rezevaciju u željenom terminu! U skladu sa pravilom POP COURT: 
    \n1) Rezervacija je moguća najkasnije ${
        caledarConst.MIN_EVENT_TIME_MILI_SEC / caledarConst.HOUR_MILI_SEC
    }h pre početka željenog termina.\n2) U toku jednog dana može se rezervisati nakmanje ${
        caledarConst.MIN_EVENT_TIME_MILI_SEC / caledarConst.HOUR_MILI_SEC
    }h a najviše ${
        caledarConst.TWO_HOUR_MILI_SEC / caledarConst.HOUR_MILI_SEC
    }h.\n3) Proverite da li imate dovoljno kredita za željenu rezervaciju.\n\nZa sve dodatne informacije kontakt telefon:\n060/36 22 22 6`,
    TERMIN_CLICK_IN_PAST: `U skladu sa pravilom POP COURT \nTermin se može otkazati najkasnije ${caledarConst.WINTER_TIME_CANCELLATION}h pre rezervisanog termina!\n\nZa sve dodatne informacije zamolili bismo vas da nas kontaktirate. tel: 060/36 22 22 6`,
    DISABLED_USER:
        "Da li ste sigurni da želite da onemogućite korisnika da se uloguje?",
    UPDATE_USER_TITLE: "Izmena Korisnika",
    INVALID_EMAIL:
        "Molimo Vas da unesete ispravnu e-mail adresu ili korisnik sa ovom e-mail adresom već postoji.",
    INVALID_PASS: "Šifra mora da sadrži najmanje 8 karaktera.",
    INVALID_PASS_CONFIRM: "Šifre moraju da budu iste.",
    INVALID_NAME: "Ime mora da sadrži najmanje 3 karaktera.",
};
